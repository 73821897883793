// Import package components
import moment from "moment";
import TableLoading from "../../common/Loading/table_loading";

const SchedulerActivities = ({ logs, loading, scheduler }) => {
  return (
    <div
      id="scheduler-activities"
      className="uk-modal-full hms-modals uk-modal uk-open"
      uk-offcanvas="flip: true; overlay: true"
    >
      <div className="uk-offcanvas-bar">
        <div className="hms-modals-main">
          <button
            className="uk-offcanvas-close uk-close-large"
            type="button"
            uk-close="true"
          ></button>
          <div className="uk-modal-header">
            <h2 className="uk-modal-title">Activities</h2>
          </div>
          <div className="uk-modal-body uk-margin-top">
            {loading ? TableLoading(1) : (
              <ul class="uk-list uk-list-divider uk-timeline">
                {
                  logs.map((log) => (
                    <li>
                      <div class="uk-timeline-content">
                        <div class="uk-flex uk-flex-between">
                          <div class="uk-text">
                            {log.event_type ? `${log.event_type[0].toUpperCase()}${log.event_type.slice(1)}d` : ''}
                            {' '}on{' '}
                            {moment.utc(log.data_created).local().format('MM/DD/YYYY')}
                            {' '}at{' '}
                            {moment.utc(log.data_created).local().format('hh:mm a')}
                            {' '}by{' '}
                            {log.first_name} {log.last_name}
                          </div>
                        </div>
                        <div class="uk-overflow-auto uk-background-muted uk-padding-small uk-border-rounded">
                          {log.prev_fields.body}
                        </div>
                      </div>
                    </li>
                  ))
                }
                {logs[0]?.event_type !== 'create' && (
                  <li>
                    <div class="uk-timeline-content">
                      <div class="uk-flex uk-flex-between">
                        <div class="uk-text">
                          Created on {' '}
                          {moment.utc(scheduler.created_at).local().format('MM/DD/YYYY')}
                          {' '}at{' '}
                          {moment.utc(scheduler.created_at).local().format('hh:mm a')}
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchedulerActivities;
