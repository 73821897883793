// Import package components
import { createContext, useState } from "react";

import SchedulerService from "../services/scheduler.service";

export const SchedulerContext = createContext();
export const SchedulerProvider = ({ children }) => {
  const [isNew, setIsNew] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [listMessages, setListMessages] = useState([]);
  const [message, setMessage] = useState({});
  const [scheduledMsgs, setScheduledMsgs] = useState([]);
  const [sources, setSources] = useState([]);
  const [logs, setLogs] = useState([]);
  const [logLoading, setlogLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const contextScheduler = {
    isEditing,
    setIsEditing,
    isNew,
    setIsNew,
    listMessages,
    message,
    setMessage,
    scheduledMsgs,
    sources,
    logs,
    initMessage: () => {
      setMessage({
        name: "",
        active: true,
        lmb: false,
        email: false,
        sms: true,
        body: "",
        tags: [],
        send_time: "10:00 AM",
        send_day: -99999,
        reference_direction: "before",
        send_to: "Guests",
        message_type: "Arrival/Departure",
        flags: [],
        pending_balance: "",
        contract_signed: "",
      });
    },

    getScheduler: () => {
      setLoading(true);
      SchedulerService.selectScheduledMessageList()
        .then((res, err) => {
          setLoading(false);
          setScheduledMsgs(res.data);
        })
        .catch((err) => console.log(err));
    },
    selectScheduledMessageList: () => {
      setLoading(true);
      SchedulerService.selectScheduledMessageList()
        .then((res, err) => {
          setLoading(false);
          setListMessages(res.data);
        })
        .catch((err) => console.log(err));
    },

    insertScheduledMessage: async (objMessage) => {
      setLoading(true);
      let res = await SchedulerService.insertScheduledMessage(objMessage);
      if (res.data) {
        setLoading(false);
        setListMessages((prev) => [...prev, res.data]);
        return res.data;
      }
    },

    updateScheduledMessage: async (objMessage) => {
      delete objMessage.created_at;
      delete objMessage.updated_at;
      let res = await SchedulerService.updateScheduledMessage(objMessage);
      if (res.data) {
        setListMessages(
          listMessages.map((indexMessage) =>
            indexMessage.scheduled_message_id === res.data.scheduled_message_id
              ? res.data
              : indexMessage,
          ),
        );
        setLoading(false);
        return res.data;
      }
    },

    deleteScheduledMessage: () => {
      SchedulerService.deleteScheduledMessage(message)
        .then((res, err) =>
          setListMessages(
            listMessages.filter(
              (indexMessage) =>
                indexMessage.scheduled_message_id !==
                message.scheduled_message_id,
            ),
          ),
        )
        .catch((err) => console.log(err));
    },

    getSources: () => {
      SchedulerService.getSources()
        .then((res, err) => {
          let sourceList = res.data.map((s) => s.formatted_source);
          sourceList.sort((a, b) => a > b ? 1 : -1);
          setSources(sourceList);
        })
        .catch((err) => console.log(err));
    },

    getLogs: async (msg_id) => {
      setlogLoading(true);
      try {
        const res = await SchedulerService.getLogs(msg_id);
        if (res.data) {
          setLogs(res.data);
        }
        setlogLoading(false);
      } catch (error) {
        console.log(error);
        setlogLoading(false);
      }
    },

    loading,
    logLoading,
  };

  return (
    <SchedulerContext.Provider value={contextScheduler}>
      {children}
    </SchedulerContext.Provider>
  );
};
