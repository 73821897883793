import { useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import UIkit from "uikit";
import moment from "moment";

import { AppContexts } from "../../providers";
import Intercom from "@intercom/messenger-js-sdk";

const Dashboard = () => {
  const { user, setUser } = useContext(AppContexts.AuthContext);
  const { get: getRental, rentals } = useContext(AppContexts.RentalsContext);

  const { emailDNSCheck, dnsErrors, dashboardCounts, counts } = useContext(AppContexts.UtilContext);

  const announcementsContext = useContext(AppContexts.AnnouncementsContext);
  const history = useHistory();

  useEffect(() => {
    document.title = "Welcome - RueBaRue";
    getRental();
    emailDNSCheck();
    dashboardCounts();

    Intercom({
      app_id: 'f3c0pquv',
    });

    // Disabled this Intercom version as accounts where getting switched
    // Intercom({
    //   app_id: "f3c0pquv",
    //   user_id: user?.user_id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    //   name: user?.user?.first_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    //   email: user?.user?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    //   created_at: Date.now(), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    // });


    // if (!document.getElementById("hs-script-loader")) {
    //   const script = document.createElement("script");
    //   script.id = "hs-script-loader";
    //   script.defer = "defer";
    //   script.src = "https://js.hs-scripts.com/7968337.js";
    //   script.async = true;
    //   document.body.appendChild(script);
    // } else {
    //   let hubspot = document.getElementById(
    //     "hubspot-messages-iframe-container",
    //   );
    //   if (hubspot) {
    //     hubspot.style.visibility = "visible";
    //   }
    // }

    // return () => {
    //   let hubspot = document.getElementById(
    //     "hubspot-messages-iframe-container",
    //   );
    //   if (hubspot) {
    //     hubspot.style.visibility = "hidden";
    //   }
    // };
  }, []);

  const property_error_cnt = useMemo(
    () => rentals.filter((rental) => rental.pms_id === "").length,
    [rentals],
  );

  const destination_error_cnt = useMemo(
    () => rentals.filter((rental) => !+rental.destination_id).length,
    [rentals],
  );

  const dismissAnnouncement = async (user_id, announcement_id) => {
    announcementsContext.dismissAnnouncement(user_id, announcement_id);
    const announcements = user.announcements.filter(
      (a) => a.id !== announcement_id,
    );
    setUser({ ...user, announcements: announcements });
  };

  const isAdmin = useMemo(() => {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; admin_token=`);

    let cookie = null;
    if (parts.length === 2) {
      cookie = parts.pop().split(";").shift();
    }

    return !!cookie;
  }, []);

  return (
    <section id="dashboard-template">
      <div id="hms-page-title">
        <h1 className="uk-heading-small">
          Welcome, {`${user.user.first_name} ${user.user.last_name}`}
        </h1>
      </div>
      <div id="hms-main-body">
        {!!isAdmin && dnsErrors?.length > 0 && (
          <div className="dns-errors">
            {dnsErrors.map((err) => (
              <p>{err}</p>
            ))}
          </div>
        )}
        <div className="uk-flex">
          <div className="uk-width-3-3">
            {(!!property_error_cnt || !!destination_error_cnt) && (
              <div className="uk-flex uk-flex-center">
                <div className="uk-width-1-1 hms-notification-feed">
                  {!!property_error_cnt && (
                    <div className="hms-notification-box">
                      <div className="hms-error-count">
                        {property_error_cnt}
                      </div>
                      <div className="hms-error-msg">
                        <span>
                          Home Guide(s) without a Property ID. Guests will not
                          receive automated messages unless resolved.{" "}
                        </span>
                        <a
                          href="https://intercom.help/ruebarueapp/en/articles/9792136-how-to-resolve-a-home-guide-missing-error-alert"
                          target="blank"
                        >
                          Learn how to resolve it.
                        </a>
                      </div>
                    </div>
                  )}
                  {!!destination_error_cnt && (
                    <div className="hms-notification-box">
                      <div className="hms-error-count">
                        {destination_error_cnt}
                      </div>
                      <div className="hms-error-msg">
                        <span>Home Guide(s) not linked to a Area Guide. </span>
                        <a
                          href="https://intercom.help/ruebarueapp/en/articles/9792214-how-to-resolve-a-home-guide-not-linked-to-an-area-guide-alert"
                          target="blank"
                        >
                          Learn how to resolve it.
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* <div className="uk-card uk-card-small uk-card-default hms-card card-how-started">
              <div className="uk-flex uk-flex-right uk-flex-middle">
                <div className="hms-note hide-long-arrow">
                  <div>I'm a pro at RueBaRue, hide this page forever</div>
                </div>
                <button className="uk-button hms-simple-icon-btn" type="button">
                  <img src="/images/close-small.svg" />
                </button>
              </div>
              <div>
                <div className="card-heading">
                  Here&apos;s how to get started:
                </div>
              </div>
              <div className="uk-margin-top">
                <div className="uk-flex uk-flex-between uk-flex-middle">
                  <div>
                    <div className="uk-inline-item">
                      <img src="/images/branding.png" />
                    </div>
                    <div className="uk-inline-item dsb-icon-heading">
                      Brand your guestbook
                    </div>
                  </div>
                  <div>
                    <label htmlFor="brandguestbook" className="hms-checkbox">
                      <input
                        type="checkbox"
                        id="brandguestbook"
                        defaultChecked
                      />
                      <span className="hms-checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="uk-margin-top uk-margin-bottom">
                  <hr />
                </div>
                <div className="uk-flex uk-flex-between uk-flex-middle">
                  <div>
                    <div className="uk-inline-item">
                      <img src="/images/update-propperty.png" />
                    </div>
                    <div className="uk-inline-item dsb-icon-heading">
                      Update your Home Guide
                    </div>
                  </div>
                  <div>
                    <label htmlFor="updateMsProperty" className="hms-checkbox">
                      <input type="checkbox" id="updateMsProperty" />
                      <span className="hms-checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="uk-margin-top uk-margin-bottom">
                  <hr />
                </div>
                <div className="uk-flex uk-flex-between uk-flex-middle">
                  <div>
                    <div className="uk-inline-item">
                      <img src="/images/property-guide.png" />
                    </div>
                    <div className="uk-inline-item dsb-icon-heading">
                      Add a Home Guide
                    </div>
                  </div>
                  <div>
                    <label htmlFor="addPropertyGuide" className="hms-checkbox">
                      <input type="checkbox" id="addPropertyGuide" />
                      <span className="hms-checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="uk-margin-top uk-margin-bottom">
                  <hr />
                </div>
                <div className="uk-flex uk-flex-between uk-flex-middle">
                  <div>
                    <div className="uk-inline-item">
                      <img src="/images/local-area-guide.png" />
                    </div>
                    <div className="uk-inline-item dsb-icon-heading">
                      Add a Local Area Guide
                    </div>
                  </div>
                  <div>
                    <label htmlFor="addLocalAreaGuide" className="hms-checkbox">
                      <input type="checkbox" id="addLocalAreaGuide" />
                      <span className="hms-checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="uk-margin-top uk-margin-bottom">
                  <hr />
                </div>
                <div className="uk-padding-top-small">
                  <div className="uk-flex uk-flex-between">
                    <div>
                      <strong>
                        Are you an enterprise with more than X customers?
                      </strong>
                      <br />
                      Please{" "}
                      <a href="#" className="hms-anchor-tag">
                        contact enterprise support
                      </a>{" "}
                      for assistance
                      <br /> with setting up your account.
                    </div>
                    <div>
                      <strong>Need help?</strong>
                      <br />
                      Visit our{" "}
                      <a href="#" className="hms-anchor-tag">
                        Resources
                      </a>{" "}
                      page for FAQs
                      <br /> and step-by-step instructions.
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="uk-margin-bottom uk-flex uk-flex-wrap">
              <div
                className="uk-card uk-card-small uk-card-default hms-card hms-card-pointer uk-width-1-4 uk-margin-right uk-margin-bottom"
                onClick={() => history.push("/guests")}
              >
                <div className="card-heading uk-inline-item">
                  Guests Arriving
                </div>
                <span className="small-notification">Next 3 Days</span>
                <div className="hms-blue-number">{counts.reservation || 0}</div>
              </div>

              <div
                className="uk-card uk-card-small uk-card-default hms-card hms-card-pointer uk-width-1-4 uk-margin-right uk-margin-bottom"
                onClick={() => history.push("/properties")}
              >
                <div className="card-heading uk-inline-item">Home Guides</div>
                <span className="small-notification">&nbsp;</span>
                <div className="hms-blue-number">{counts.rental || 0}</div>
              </div>

              <div
                className="uk-card uk-card-small uk-card-default hms-card hms-card-pointer uk-width-1-4 uk-margin-right uk-margin-bottom"
                onClick={() => history.push("/areas")}
              >
                <div className="uk-margin-bottom">
                  <div className="card-heading uk-inline-item">Area Guides</div>
                  <span className="small-notification">&nbsp;</span>
                  <div className="hms-blue-number">
                    {counts.destination || 0}
                  </div>
                </div>
              </div>

              <div
                className="uk-card uk-card-small uk-card-default hms-card hms-card-pointer uk-width-1-4 uk-margin-right uk-margin-bottom"
                onClick={() => history.push("/scheduler")}
              >
                <div className="card-heading uk-inline-item">
                  Scheduled Messages
                </div>
                <span className="small-notification">Active</span>
                <div className="hms-blue-number">{counts.scheduler || 0}</div>
              </div>

              <div
                className="uk-card uk-card-small uk-card-default hms-card hms-card-pointer uk-width-1-4 uk-margin-right uk-margin-bottom"
                onClick={() => history.push("/contacts")}
              >
                <div className="card-heading uk-inline-item">Contacts</div>
                <span className="small-notification">&nbsp;</span>
                <div className="hms-blue-number">{counts.contact || 0}</div>
              </div>
            </div>

            {/* 
            <div className="uk-margin-top">
              <div
                className="uk-card uk-card-small uk-card-default hms-card hms-card-pointer"
                onClick={() => {
                  history.push("/reports/guestbook-viewing-times");
                }}
              >
                <div className="uk-margin-bottom">
                  <div className="card-heading uk-inline-item">
                    Guestbook Viewing Time
                  </div>
                  <span className="small-notification">All Time</span>
                </div>
              </div>
            </div>

            <div className="uk-margin-top">
              <div
                className="uk-card uk-card-small uk-card-default hms-card hms-card-pointer"
                onClick={() => {
                  history.push("/reports/message-sent-vs-guestbook-view");
                }}
              >
                <div className="uk-margin-bottom">
                  <div className="card-heading uk-inline-item">
                    Messages Sent Vs. Guestbook Views
                  </div>
                  <span className="small-notification">All Time</span>
                </div>
              </div>
            </div>
          */}

            {/* 
          <div className="uk-width-1-3 uk-margin-left">
            {(user.details.type === "trial" || user.details.type === "trial-full") &&
              moment(user.details.trial_end) > moment() && (
                <div className="uk-card uk-card-small uk-card-default hms-card trial-account-banner">
                  <div className="uk-flex uk-flex-center">
                    <div>
                      {moment(user.details.trial_end).diff(moment(), "days")}
                    </div>
                    <div>
                      days left in
                      <br />
                      your free trial
                      <div>
                        <a className="#">Upgrade Now &gt;</a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
          */}
          </div>
          {/* <div className="uk-width-1-3 uk-margin-left">
            {user.announcements.map((announcement) => (
              <div
                className="uk-card uk-card-small uk-card-default hms-card card-new-reue uk-margin-bottom"
                key={`ann_${announcement.id}`}
              >
                <div className="new-reue-close">
                  <button
                    className="uk-button hms-simple-icon-btn"
                    type="button"
                    onClick={() =>
                      dismissAnnouncement(user.user_id, announcement.id)
                    }
                  >
                    <img src="/images/close-small.svg" />
                  </button>
                </div>
                <h4>{announcement.title}</h4>
                <p>{announcement.description}</p>
                <div className="r-learn-more">
                  <a className="#" href={announcement.link} target="_blank">
                    <span uk-icon="icon:  chevron-right; ratio: 0.8"></span>{" "}
                    Learn More
                  </a>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
