import { useEffect, useContext } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import UIkit from "uikit";

import Overview from "./Overview";
import ReportsNavbar from "./Layout/navbar";
import GuestBookView from "./GuestBookView";
import GuestbookViewsBySection from "./GuestbookViewsBySection";
import GuestbookViewsByDevice from "./GuestbookViewsByDevice";
import MessagesLog from "./MessageLog";
import AutoSentSMS from "./AutoSentSMS";
import AutoSentEmail from "./AutoSentEmail";
import PropertyGuideView from "./PropertyGuideView";
import DestinationGuideView from "./DestinationGuideView";
import Macros from "./Macros";
import FiveStarRating from "./FiveStarRating";
import ExtendStay from "./ExtendStay";
import CheckInOut from "./CheckInOut";
import Webchat from "./Webchat";
// import GuestbookViewingTimes from "./GuestbookViewingTimes";
// import MessagesSentVsGuestbookViews from "./MessagesSentVsGuestbookViews";
import ExtendstayInOut from "./ExtendstayInOut";
import ExtrasView from "./ExtrasView";

const Reports = () => {
  const { path } = useRouteMatch();
  useEffect(() => {
    document.title = "Reports - RueBaRue";
  }, []);

  return (
    <section id="hms-reports-template">
      <div id="hms-page-title">
        <h1 className="uk-heading-small">Reports</h1>
      </div>
      <div id="hms-main-body">
        <div className="uk-width-1-1" uk-grid="true">
          <ReportsNavbar />
          <div className="uk-width-expand">
              <Switch>
                <Route exact path={path}>
                  <Overview />
                </Route>
                <Route exact path={`${path}/overview`}>
                  <Overview />
                </Route>
                <Route exact path={`${path}/message-log`}>
                  <MessagesLog />
                </Route>
                <Route exact path={`${path}/guestbook-views`}>
                  <GuestBookView />
                </Route>
                <Route exact path={`${path}/extras-views`}>
                  <ExtrasView />
                </Route>
                {/* <Route exact path={`${path}/guestbook-by-section`}>
                  <GuestbookViewsBySection />
                </Route>
                <Route exact path={`${path}/guestbook-by-device`}>
                  <GuestbookViewsByDevice />
                </Route> */}
                <Route exact path={`${path}/home-guide-views`}>
                  <PropertyGuideView />
                </Route>
                <Route exact path={`${path}/area-guide-views`}>
                  <DestinationGuideView />
                </Route>
                <Route exact path={`${path}/fivestar-rating`}>
                  <FiveStarRating />
                </Route>
                <Route exact path={`${path}/check-in-out`}>
                  <CheckInOut />
                </Route>
                <Route exact path={`${path}/request-check-in-out`}>
                  <ExtendstayInOut />
                </Route>
                <Route exact path={`${path}/automated-sms-sent`}>
                  <AutoSentSMS />
                </Route>
                <Route exact path={`${path}/automated-email-sent`}>
                  <AutoSentEmail />
                </Route>
                <Route exact path={`${path}/extend-stay`}>
                  <ExtendStay />
                </Route>
                <Route exact path={`${path}/webchat`}>
                  <Webchat />
                </Route>
                <Route exact path={`${path}/macros`}>
                  <Macros />
                </Route>
              </Switch>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reports;
