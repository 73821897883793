import { createContext, useState } from "react";

import utilService from "../services/util.service";
import inboxService from "../services/inbox.service";

export const UtilContext = createContext();
export const UtilProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  // Google Auto Complete
  const [predictions, setPredictions] = useState([]);
  const [place, setPlace] = useState({});
  const [imageUrls, setImageUrls] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [uploadedUrl, setUploadedUrl] = useState("");
  const [pmsFields, setPMSFields] = useState(null);
  const [notification, setNotification] = useState(null);
  const [counts, setCounts] = useState({});

  const [dnsErrors, setDNSErrors] = useState([]);

  let utilContext = {
    loading,
    imageUrls,
    imageUrl,
    pmsFields,
    dnsErrors,
    counts,

    predictions,
    setPredictions,
    notification,
    setNotification,

    // Generates a random UUID
    genUUID: () => {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16),
      );
    },

    // Google Auto Complete
    autoComplete: async (input, location = "") => {
      let result = await utilService.autoComplete(input, location);
      let { results, error } = result.data;
      if (error) {
        console.log(error);
      } else {
        setPredictions(results.predictions);
      }
    },
    // Google Places
    place: async (google_place_id) => {
      let result = await utilService.place(google_place_id);
      let { results, error } = result.data;
      if (error) {
        console.log(error);
      } else {
        setPlace(results.result);
        return results.result;
      }
    },
    //Google Images
    images: async (google_place_ids) => {
      let temp = google_place_ids?.map(async (id) => {
        let result = await utilService.images(id);
        let { google_place_id, url } = result.data;
        return { google_place_id, url };
      });
      Promise.all(temp).then((res) => {
        setImageUrls(res);
      });
    },
    image: async (google_place_id) => {
      let result = await utilService.images(google_place_id);
      let { results, error } = result.data;
      if (error) {
        throw error;
      } else {
        setImageUrl(results);
        return results;
      }
    },
    //uploaded Url
    uploadedUrl,
    //Upload image to S3
    uploadFile: async (file, where) => {
      let uploadObject = {
        filename: where,
        mime: file.type,
      };
      const result = await utilService.uploadFile(uploadObject);
      const { error, url } = result.data;
      if (error) {
        throw error;
      } else {
        let headers = new Headers();
        headers.append("Content-Type", file.type);

        let options = {
          method: "PUT",
          headers: headers,
          body: file,
          redirect: "follow",
        };

        let response = await fetch(url, options)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            throw error;
          });

        let uploadedUrl = url;
        let realUrl = uploadedUrl.substring(0, uploadedUrl.indexOf("?"));
        setUploadedUrl(realUrl);
        return realUrl;
      }
    },
    getPmsFields: async () => {
      setLoading(true);
      const resp = await utilService.getPmsFields();
      setPMSFields(resp.data);
      setLoading(false);
    },

    getNotification: async (msg) => {
      if (!!msg?.thread_id) {
        const response = await inboxService.getThreadOverview(msg.thread_id);
        const { error, thread } = response.data;

        if (!!thread) {
          setNotification(thread);
        }
      } else {
        setNotification(null);
      }
    },

    shareGuide: async (obj) => {
      const response = await utilService.shareGuide(obj);

      const { error, thread_id } = response.data;

      if (!!thread_id) {
        console.log(thread_id);
        return thread_id;
      }
    },

    shareMessage: async (msg_type, msg_id, contact) => {
      const response = await utilService.shareMessage(
        msg_type,
        msg_id,
        contact,
      );

      const { error, thread_id } = response.data;

      if (!!thread_id) {
        console.log(thread_id);
        return thread_id;
      }
    },

    emailDNSCheck: async () => {
      let response = await utilService.emailDNSCheck();

      let errorCodes = {
        dkim1: (domain) => {
          return `There is an error with your DKIM record for ${domain}. Please please add a CNAME record with the 'host' value 'mte1._domainkey.${domain}' and the 'answer' value 'dkim1.mandrillapp.com'`;
        },
        dkim2: (domain) => {
          return `There is an error with your DKIM record for ${domain}. Please please add a CNAME record with the 'host' value 'mte2._domainkey.${domain}' and the 'answer' value 'dkim2.mandrillapp.com'`;
        },
        dmarc: (domain) => {
          return `There is an error with your DMARC record for ${domain}. Please add or update a TXT record in your domain's DNS settings settings with 'host' value '_dmarc.${domain}' and the 'answer' value 'v=DMARC1; p=none;'`;
        },
      };

      let errors = [];
      let { domains = [], error = null } = response.data;
      if (!!error) {
        console.log("DNS Check Error:", error);
      } else {
        console.log(response);

        Object.keys(domains).map((key) => {
          let domain = domains[key];

          for (var x in domain) {
            if (!!domain[x].code) {
              errors.push(errorCodes[x](key));
            }
          }
        });
      }

      setDNSErrors(errors);
    },

    dashboardCounts: async () => {
      let response = await utilService.dashboardCounts();
      setCounts(response.data);
    }
  };

  return (
    <UtilContext.Provider value={utilContext}>{children}</UtilContext.Provider>
  );
};
