import { httpV2 } from "../http.js";

const UtilService = {
  // Google Auto Complete
  autoComplete: (input, location, radius = 10000) =>
    httpV2.post("google/autocomplete", { input, location, radius }),
  place: (google_place_id) =>
    httpV2.get(`google/place?google_place_id=${google_place_id}`),
  images: (google_place_id) =>
    httpV2.get(`google/images?google_place_id=${google_place_id}`),
  toggleFlag: (flag, item_id, item_type) =>
    httpV2.post(`/flags/toggle`, { flag, item_id, item_type }),
  //Upload Image to S3
  uploadFile: (fileObject) => httpV2.post("utils/uploads/url", fileObject),
  getPmsFields: () => httpV2.get("utils/pms/fields"),
  shareGuide: (obj) => httpV2.post("utils/share", obj),
  shareMessage: (msg_type, msg_id, contact) =>
    httpV2.post(`utils/share/${msg_id}`, { msg_type, contact }),
  emailDNSCheck: () => httpV2.post(`utils/email_dns_check`, {}),
  dashboardCounts: () => httpV2.get(`utils/counts`),
};

export default UtilService;
