// Import base components
import { httpV2 } from "../http.js";

const ReportsService = {
  getOverviews: (startDate, endDate, page) =>
    httpV2.get("/reports/overviews", {
      params: {
        start_date: startDate,
        end_date: endDate,
        page,
      },
    }),

  getGuestBooks: (start_date, end_date, page, per, filter) =>
    httpV2.get("/reports/guestbooks", {
      params: {
        start_date,
        end_date,
        filter,
        page,
        per,
      },
    }),

  getFiveStar: (start_date, end_date, page, per, filter) =>
    httpV2.get("/reports/fivestar", {
      params: {
        start_date,
        end_date,
        page,
        per,
        filter,
      },
    }),

  getExtendStay: (start_date, end_date, page, per, filter) =>
    httpV2.get("/reports/extendstay", {
      params: {
        start_date,
        end_date,
        page,
        per,
        filter,
      },
    }),

  getGuestChecks: (start_date, end_date, page, per, filter) =>
    httpV2.get("/reports/guestcheck", {
      params: {
        start_date,
        end_date,
        page,
        per,
        filter,
      },
    }),
  
  getExtendStayChecks: (start_date, end_date, page, per, filter) =>
    httpV2.get("/reports/extendstayCheck", {
      params: {
        start_date,
        end_date,
        page,
        per,
        filter,
      },
    }),

  getWebChat: (start_date, end_date, page, per, filter) =>
    httpV2.get("/reports/webchat", {
      params: {
        start_date,
        end_date,
        page,
        per,
        filter,
      },
    }),

  getDestinations: (start_date, end_date, page, per) =>
    httpV2.get("/reports/destinations", {
      params: {
        start_date,
        end_date,
        page,
        per,
      },
    }),

  getImported: (start_date, end_date, page) =>
    httpV2.get("/reports/imported", {
      params: {
        start_date,
        end_date,
        page,
      },
    }),

  getAutomated: (type, start_date, end_date, page, per, filter) =>
    httpV2.get(`/reports/automated/${type}`, {
      params: {
        start_date,
        end_date,
        page,
        per,
        filter,
      },
    }),

  getHistory: (start_date, end_date, page, per, filter) =>
    httpV2.get("/reports/history", {
      params: {
        start_date,
        end_date,
        filter,
        page,
        per,
      },
    }),

  getRental: (start_date, end_date, page, per) =>
    httpV2.get("/reports/rentals", {
      params: {
        start_date,
        end_date,
        page,
        per,
      },
    }),

  getMacros: () => httpV2.get("/reports/macros"),

  getExtras: (start_date, end_date, page, per) =>
    httpV2.get("/reports/extras", {
      params: {
        start_date,
        end_date,
        page,
        per,
      },
    }),
};

export default ReportsService;
