import { useState, useMemo, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Link } from "uikit-react";
import { AppContexts } from "../../../providers";
import UIkit from "uikit";

const ReportsNavbar = () => {
  const {
    startDate,
    endDate,
    active,
    setActive,
    getOverview,
    getHistory,
    getGuestbook,
    getGuestbookViewsBySection,
    getGuestbookViewsByDevice,
    getRental,
    getGuide,
    getAutomatedSms,
    getAutomatedEmail,
    getMacros,
    getFiveStar,
    getExtendStay,
    getGuestChecks,
    getExtendStayChecks,
    getWebChat,
  } = useContext(AppContexts.ReportsContext);

  const history = useHistory();
  const pathArray = useMemo(() => history.location.pathname.split("/"), [history.location.pathname]);
  const path = pathArray[pathArray.length - 1].replace("#", "");
  const [expanded, setExpanded] = useState(null);

  const toggleExpand = (label) => {
    setExpanded((prev) => (prev === label ? null : label));
  };

  const reportMap = {
    overview: getOverview,
    "message-log": getHistory,
    "guestbook-views": getGuestbook,
    "guestbook-by-section": getGuestbookViewsBySection,
    "guestbook-by-device": getGuestbookViewsByDevice,
    "home-guide-views": getRental,
    "area-guide-views": getGuide,
    "automated-sms-sent": getAutomatedSms,
    "automated-email-sent": getAutomatedEmail,
    "fivestar-rating": getFiveStar,
    "extend-stay": getExtendStay,
    "check-in-out": getGuestChecks,
    "request-check-in-out": getExtendStayChecks,
    webchat: getWebChat,
    macros: getMacros,
  };

  const reportTabs = [
    { path: "overview", label: "Overview" },
    { path: "message-log", label: "Text Message Logs" },
    {
      label: "Guestbook Analytics",
      subItems: [
        { path: "guestbook-views", label: "Guestbook Views" },
        // { path: "guestbook-by-section", label: "By Section" },
        // { path: "guestbook-by-device", label: "By Device" },
        { path: "extras-views", label: "Extras Views" },
        { path: "home-guide-views", label: "Home Guide Views" },
        { path: "area-guide-views", label: "Area Guide Views" },
      ],
    },
    {
      label: "Guest Responses",
      subItems: [
        { path: "fivestar-rating", label: "Five Star Ratings" },
        { path: "check-in-out", label: "Check-In/Check-Out" },
        { path: "request-check-in-out", label: "Request Early Check-In/Late Check-Out" },
      ],
    },
    {
      label: "Messages Sent",
      subItems: [
        { path: "automated-sms-sent", label: "Automated Text" },
        { path: "automated-email-sent", label: "Automated Emails" },
        { path: "extend-stay", label: "Extend Stays Text" },
      ],
    },
    { path: "webchat", label: "Webchat Activities" },
    { path: "macros", label: "Property Macros" },
  ];

  useEffect(() => {
    if (path === "reports") history.push("/reports/overview");
    setActive(path);
    const fetchData = reportMap[path];
    if (fetchData) {
      fetchData(startDate, endDate);
    }

    const unlisten = history.listen((location) => {
      const newPath = location.pathname.split("/").pop().replace("#", "");
      setActive(newPath);
    });

    return () => unlisten();
  }, [path, history]);

  const handleNavigation = (path, parentLabel) => {
    setActive(path);
    history.push(`/reports/${path}`);

    if (parentLabel) {
      setExpanded(parentLabel);
    } else {
      setExpanded(null);
    }

    UIkit.drop(document.querySelector(".uk-open"))?.hide();
  };

  return (
    <div className="uk-width-auto">
      <ul
        className="uk-tab-right hms-report-option-tabs hms-left-tabs uk-tab"
        uk-tab
      >
        {reportTabs.map((item) => (
          <li key={item.label || item.path} className={item.subItems ? "uk-parent" : active === item.path ? "uk-active" : ""}>
            {item.path ? (
              <a
                onClick={() => handleNavigation(item.path)}
              >
                {item.label}
              </a>
            ) : (
              <>
                <div
                  className="uk-parent-title"
                  onClick={() => toggleExpand(item.label)}
                  style={{ cursor: "pointer" }}
                  aria-expanded={expanded === item.label ? "true" : "false"}
                >
                  {item.label}{" "}
                  <span className="uk-icon" uk-icon="icon: chevron-down"></span>
                </div>
                {expanded === item.label && (
                  <ul className="uk-nav-sub">
                    {item.subItems.map((subItem) => (
                      <li 
                        key={subItem.path}
                        className={active === subItem.path ? "uk-active" : ""}
                      >
                        <a onClick={() => handleNavigation(subItem.path, item.label)}>
                          {subItem.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReportsNavbar;