// Import package components
import { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { Flex } from "uikit-react";
import { useCookies } from "react-cookie";
import moment from "moment";

import Pagination from "../../common/Pagination";
import { AppContexts } from "../../../providers";
import RecordsPerPage from "../../common/RecordsPerPage";

import FilterPanel from "../Common/FilterPanel";
import usePath from "../Common/History";

const PropertyGuideView = () => {
  const [page, setPage] = useState(0);
  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const { active, rental, startDate, endDate, getRental } = useContext(
    AppContexts.ReportsContext
  );

  const handlePageChange = useCallback((page) => setPage(page), []);

  const { total, paginated } = rental;

  const path = usePath();

  useEffect(() => {
    if (path !== active) setPage(0);
  }, [path]);

  useEffect(() => {
    if (active === "home-guide-views") getFn(startDate, endDate);
  }, [page, recordsPerPage]);

  const getFn = useCallback(
    (startDate, endDate) => {
      if (!startDate || !endDate) {
        return;
      } else {
        getRental(startDate, endDate, page, recordsPerPage);
      }
    },
    [page, recordsPerPage]
  );

  return (
    <li className="reports-tab-content">
      <h2>Home Guide Views</h2>
      <div className="uk-card hms-left-tabs-content-card uk-padding-remove">
        <FilterPanel
          startDateRange={{ max: moment().format("YYYY-MM-DD") }}
          endDateRange={{ max: moment().format("YYYY-MM-DD") }}
          onSubmit={getFn}
        />
        <div className="secondary">{total} Home Guide Views</div>
        <table className="uk-table uk-table-hover uk-table-divider hms-table uk-margin-small-top">
          <thead>
            <tr>
              <th>FIRST VIEWED AT</th>
              <th>PROPERTY ID</th>
            </tr>
          </thead>
          <tbody>
            {paginated?.length ? (
              paginated?.map((item, index) => (
                <tr key={index}>
                  <td>{moment.utc(item.viewed_at).local().format("MM/DD/YYYY hh:mm A")}</td>
                  <td className="reports-table-wrap-overflow">
                    <a href={`rental/${item.property_id}`}>
                      {item.property_pms_id}
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2} className="no-results">
                  No results for this date range
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <p className="footnote">
          This report only displays views when you share the home guide or the
          use the QR code.
        </p>
      </div>

      <Flex alignment="between top" className="guest-form-top-filters">
        <Pagination
          count={total}
          index={page}
          onIndexChanged={handlePageChange}
        />
        <RecordsPerPage />
      </Flex>
    </li>
  );
};

export default PropertyGuideView;
