// Import package components
import { useContext, useEffect, useState } from "react";
import { AppContexts } from "../../../providers";
import { Icon, Image } from "uikit-react";
import DestinationGuideDelete from "./guide_delete";
import {
  SortableHandle,
} from "react-sortable-hoc";

const defaultTabs = [
  "faq",
  "restaurants",
  "attractions",
  "essentials",
  "directions",
  "tours",
];

const DragHandle = SortableHandle(() => (
  <div className="uk-sortable-handle">
    <button
      className="uk-button hms-simple-icon-btn drag-handle"
      uk-tooltip="Move"
    >
      <Image src="../images/move.svg" />
    </button>
  </div>
));


const SortableItemDiv = ({ index, no, tab, val, onUpdate, onDelete, editDestinationGuide, setEditDestinationGuide, handleUpdateData }) => {
  return (
    <li className="drag-item" data-id={val.type}>
      <div className="uk-width-1-1">
        <div className="uk-flex uk-flex-between uk-flex-middle">
          <DragHandle />
          <div>
            {defaultTabs.indexOf(val.type) !== -1 ? (
              <button
                type="button"
                className="uk-button hms-simple-icon-btn lock-icon-grey"
              ></button>
            ) : (
              <button
                type="button"
                className="uk-button hms-simple-icon-btn"
                uk-toggle="target: #destination-delete"
                uk-tooltip="Delete"
                onClick={() => {
                  onDelete(val);
                }}
              >
                <Image src="/images/delete-icon.svg" />
              </button>
            )}
          </div>
          <div className="m-w-290 uk-flex uk-flex-middle" style={{ padding: '10px 0' }}>
            {editDestinationGuide && editDestinationGuide.type === val.type ?
              <>
                <div className="uk-flex-inline">
                  <input
                    className="uk-input"
                    type="text"
                    value={val.label}
                    onInput={(e) => {
                      onUpdate(val.type, "label", e);
                    }}
                  />
                  <span onClick={handleUpdateData}>
                    <Icon options="check" button={true} />
                  </span>
                  <span onClick={() => setEditDestinationGuide(null)}>
                    <Icon options="close" button={true} />
                  </span>
                </div>
              </>
              : <>
                <span>{val.label}</span>
                <button
                  className="uk-button hms-simple-icon-btn"
                  onClick={() => setEditDestinationGuide(val)}
                >
                  <Image src="/images/edit-icon.svg" />
                </button>
              </>
            }
          </div>
          <div>
            <div className="autosend-checkbox">
              <label for={`guideCheckbox${no}`} className="setting-switch">
                <input
                  type="checkbox"
                  id={`guideCheckbox${no}`}
                  checked={val.show_on_guide}
                  onInput={(e) => {
                    onUpdate(val.type, "show_on_guide", {
                      target: { value: !val.show_on_guide },
                    });
                  }}
                />
                <span className="setting-slider setting-round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-width-1-1 m-t-5"></div>
    </li>
  );
};

const SortableList = ({ tabs, onSortEnd, onUpdate, onDelete, handleUpdateData, setEditDestinationGuide, editDestinationGuide }) => {
  useEffect(() => {
    let container = document.querySelector(".drag-container");
    container.addEventListener("stop", () => {
      let ids = [...container.querySelectorAll(".drag-item")].map((el) => {
        return el.dataset["id"];
      });

      onSortEnd(ids);
    });
  }, []);

  return (
    <ul
      uk-sortable="handle: .uk-sortable-handle"
      className="drag-container"
      onStop={() => {
        console.log("stopped");
      }}
    >
      {tabs?.map((tab, index) => (
        <SortableItemDiv
          key={tab.type}
          index={index}
          no={index}
          val={tab}
          onUpdate={onUpdate}
          onDelete={onDelete}
          editDestinationGuide={editDestinationGuide}
          setEditDestinationGuide={setEditDestinationGuide}
          handleUpdateData={handleUpdateData}
        />
      ))}
    </ul>
  );
};

const DestinationGuideTags = () => {
  const { set, settings, loading, setDeleted, active } = useContext(
    AppContexts.SettingsContext,
  );
  const [addTab, setAddTab] = useState("");
  const [tabs, setTabs] = useState([]);
  const [destination, setDestination] = useState({});
  const [updatedOrder, setUpdatedOrder] = useState(null);
  const [editDestinationGuide, setEditDestinationGuide] = useState(null);

  useEffect(() => {
    document.title = "Settings | Area Guide - RueBaRue";
    setTabs(settings.destination_tabs || []);
    setDestination(settings.destination || { tab_order: "" });
  }, [settings]);

  useEffect(() => {
    if (updatedOrder !== null) {
      set('destination', {...destination, tab_order: updatedOrder.join(",") });
      setUpdatedOrder(null)
    }
  }, [updatedOrder]);

  const handleAddTab = () => {
    let type = Math.floor(
      100000000 + Math.random() * (999999999 - 100000000 + 1),
    ).toString();
    let newOrder = [...destination.tab_order.split(","), type].join(",");

    if (addTab.trim().length > 0) {
      let temp = {
        label: addTab.trim(),
        show_on_embed: false,
        show_on_guide: true,
        isDefault: false,
        type: type,
      };

      set(["destination", "destination_tabs"], {
        destination_tabs: [...tabs, temp],
        destination: {...destination, tab_order: newOrder },
      }).then(() => {
        setAddTab("");
      });
    }
  };

  const handleUpdate = (type, field, evt) => {
    setTabs(
      tabs.map((d) => {
        return type === d.type ? { ...d, [field]: evt.target.value } : d;
      }),
    );
  };

  const handleUpdateData = async () => {
    await set('destination_tabs', tabs);
    setEditDestinationGuide(null);
  }

  const handleDelete = (tab) => {
    setDeleted(tab);
  };

  const handleReorder = (tabIds) => {
    setUpdatedOrder(tabIds)
  };

  let order = (destination.tab_order || "").split(",");
  let sorted = tabs
    .filter((t) => order.indexOf(t.type) !== -1)
    .sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type));

  return (
    <>
      <li
        className={`setting-destinationGuide-tab-content ${
          active === "destination" ? "uk-active" : ""
        }`}
      >
        <div className="uk-card hms-left-tabs-content-card mx-w-665">
          <h2>Area Guide</h2>
          <div className="uk-width-1-1">
            <div className="uk-width-1-4 guide-embed-label">
              <div className="uk-flex uk-flex-right uk-flex-middle">
                <div>Visible</div>
              </div>
            </div>
          </div>
          <SortableList
            tabs={sorted}
            useDragHandle
            transitionDuration="0"
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            onSortEnd={handleReorder}
            editDestinationGuide={editDestinationGuide}
            setEditDestinationGuide={setEditDestinationGuide}
            handleUpdateData={handleUpdateData}
          />
          <div className="uk-width-1-1 uk-margin">
            <div className="add-dest-gd-tab-wrapper">
              <div className="uk-flex uk-flex-middle">
                <input
                  className="uk-input"
                  type="text"
                  placeholder="Tab Name"
                  value={addTab}
                  onChange={(e) => setAddTab(e.target.value)}
                />
                <div className="plus-w-2">
                  <button
                    className="uk-button hms-btn hms-gray-btn btn-no-margin"
                    type="button"
                    onClick={handleAddTab}
                  >
                    {loading && <span data-uk-spinner="ratio: 0.5;"></span>}
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <DestinationGuideDelete />
    </>
  );
};

export default DestinationGuideTags;
