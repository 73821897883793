import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Flex } from "uikit-react";
import { useCookies } from "react-cookie";
import moment from "moment";

import Pagination from "../../common/Pagination";
import RecordsPerPage from "../../common/RecordsPerPage";
import { AppContexts } from "../../../providers";

import FilterPanel from "../Common/FilterPanel";
import usePath from "../Common/History";

const Webchat = () => {
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(0);
  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;

  const { active, webchats, startDate, endDate, getWebChat } = useContext(
    AppContexts.ReportsContext
  );

  const path = usePath();
  const { total, paginated } = webchats;

  useEffect(() => {
    if (path !== active) setPage(0);
  }, [path]);

  useEffect(() => {
    if (active === "webchat") getFn(startDate, endDate);
  }, [page, filter, recordsPerPage]);

  const getFn = useCallback(
    (start, end) => {
      if (!start || !end) {
        return;
      } else {
        getWebChat(
          start,
          end,
          page,
          recordsPerPage,
          filter ? filter.trim() : filter
        );
      }
    },
    [page, filter, recordsPerPage]
  );

  const handlePageChange = useCallback((page) => setPage(page), []);
  const handleFilterChange = useCallback((filter) => setFilter(filter), []);

  return (
    <li className="reports-tab-content">
      <h2>Webchat Activities</h2>
      <div className="uk-card hms-left-tabs-content-card uk-padding-remove">
        <FilterPanel
          searchTermPlaceholder="Contact Name"
          onFilterChange={handleFilterChange}
          onSubmit={getFn}
        />
        <table className="uk-table uk-table-hover uk-table-divider hms-table uk-margin-small-top">
          <thead>
            <tr>
              <th>ACTIVITY DATE</th>
              <th>CONTACT NAME</th>
              <th>PHONE</th>
              <th>DEVICE</th>
              <th>BROWSER</th>
              <th>FIRST MESSAGE</th>
            </tr>
          </thead>
          <tbody>
            {paginated?.length ? (
              paginated?.map((item) => (
                <tr>
                  <td>
                    {moment.utc(item.created_at).local().format("MM/DD/YYYY hh:mm A")}
                  </td>
                  <td>{item.guest_name}</td>
                  <td>{item.formatted_phone}</td>
                  <td>{item.device}</td>
                  <td>{item.browser}</td>
                  <td className="message">
                    <textarea rows="1" disabled>
                      {item.first_message}
                    </textarea>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="no-results">
                  No results for this date range
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Flex alignment="between top" className="guest-form-top-filters">
        <Pagination
          count={total}
          index={page}
          onIndexChanged={handlePageChange}
        />
        <RecordsPerPage />
      </Flex>
    </li>
  );
};

export default Webchat;
