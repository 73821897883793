// Import base components
import http, { httpV2 } from "../http.js";

const SchedulerService = {
	selectScheduledMessageList: () => httpV2.get("/scheduler"),
	insertScheduledMessage: (message) => httpV2.post("/scheduler", message),
	updateScheduledMessage: (message) =>
		httpV2.put(`/scheduler/${message.scheduled_message_id}`, message),
	deleteScheduledMessage: (message) =>
		httpV2.delete(`/scheduler/${message.scheduled_message_id}`),
	getSources: () => httpV2.get("/scheduler/sources"),
	getLogs: (scheduled_message_id) => httpV2.get(`/scheduler/logs/${scheduled_message_id}`),
};

export default SchedulerService;
