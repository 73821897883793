import React, {
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Image } from "uikit-react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Chart } from "react-google-charts";
import { CSVLink } from "react-csv";

import { AppContexts } from "../../../providers";
import MultipleChoice from "../question_types/multiple-choice";
import ShortAnswer from "../question_types/short-answer";
import Checkboxes from "../question_types/checkboxes";
import Range from "../question_types/range";
import GuestMessages from "../../guests/guest_messages";

const Responses = () => {
  const surveysContext = useContext(AppContexts.SurveysContext);
  const reservationContext = useContext(AppContexts.ReservationsContext);

  const [startDate, setStartDate] = useState(
    moment().subtract(7, "days").format("YYYY-MM-DD"),
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [view, setView] = useState("overview");
  const [selected, setSelected] = useState(0);

  const history = useHistory();

  useEffect(() => {
    document.title = "Survey Result - RueBaRue";
  }, []);

  useEffect(async () => {
    let survey_id = history.location.pathname.split("/")[2];
    await surveysContext.getSurveyAndResult(survey_id, startDate, endDate);
  }, [startDate, endDate]);

  const handleBack = useCallback(() => {
    history.goBack();
  }, []);

  const survey = useMemo(() => {
    const surveyResult = surveysContext.surveyResult;

    if (!surveyResult) {
      return {};
    }

    const updatedSurvey = { ...surveyResult };
    const { question_order: questionOrder, questions } = surveyResult;

    if (questionOrder) {
      const questionOrderList = questionOrder.split(",");

      updatedSurvey.questions = questionOrderList
        .map((questionId) =>
          questions?.find(
            ({ survey_question_id: surveyQuestionId, deleted }) =>
              +surveyQuestionId === +questionId && !deleted,
          ),
        )
        .filter(Boolean);

      updatedSurvey.questions.forEach((question) => {
        const {
          option_order: optionOrder,
          question_options: questionOptions,
        } = question;

        if (optionOrder) {
          const optionOrderList = optionOrder.split(",");

          question.question_options = optionOrderList
            .map((optionId) =>
              questionOptions?.find(
                ({
                  survey_question_option_id: questionOptionId,
                  type,
                  deleted,
                }) =>
                  (+questionOptionId === +optionId || +type === +optionId) &&
                  !deleted,
              ),
            )
            .filter(Boolean);
        }
      });
    }
    updatedSurvey.survey_results = updatedSurvey.survey_results ?? [];

    return updatedSurvey;
  }, [surveysContext.surveyResult]);

  const responses = useMemo(() => {
    if (!survey) {
      return [];
    } else {
      return survey.survey_results || [];
    }
  }, [survey]);

  let reservation = useMemo(() => {
    if (selected !== null) {
      return survey.survey_results[selected]?.reservation || null;
    } else {
      return null;
    }
  }, [selected]);

  const questions = useMemo(() => {
    let qs = (survey.question_order || "")
      .split(",")
      .map((id) => {
        let q = (survey.questions || []).find(
          (i) => +i.survey_question_id === +id,
        );

        if (!q) {
          return;
        }

        return {
          id: +q.survey_question_id,
          title: q.question,
          help_text: q.help_text,
          options: q.question_options,
          type: q.question_type,
          responses: {
            sum: 0,
            guest_reservation_ids: [],
            answers: {},
          },
        };
      })
      .filter((q) => !!q);

    responses.map((r) => {
      (r.answers || []).map((a) => {
        let q = qs.find((i) => +i.id === +a.survey_question_id);
        if (!q || !q.responses) {
          return;
        }

        let rs = q.responses;

        if (!rs.guest_reservation_ids.includes(+r.guest_reservation_id)) {
          rs.sum += 1;
          rs.guest_reservation_ids.push(+r.guest_reservation_id);
        }

        if (rs.answers.hasOwnProperty([a.answer])) {
          rs.answers[a.answer] += 1;
        } else {
          if (q.type === "short-answer") {
            rs.answers[a.answer || a.custom_answer] = 1;
          } else {
            rs.answers[a.answer.toString()] = 1;
          }
        }
      });
    });

    return qs;
  }, [responses]);

  const headers = useMemo(() => {
    if (questions.length == 0) return [];
    return [
      "Submitted At",
      "First Name",
      "Last Name",
      "Check-In",
      "Check-Out",
      "Property ID",
      "Reservation ID",
      ...questions.map((q) => q.title),
    ];
  }, [questions]);

  const data = useMemo(() => {
    return responses.map((r) => {
      const reservation = r.reservation || {};
      let answers = questions.map(
        (q) =>
          r.answers.find((qa) => qa.survey_question_id === q.id)?.answer || "",
      );
      return [
        moment(r.created_at).format("MM/DD/YYYY h:mm"),
        reservation?.first_name,
        reservation?.last_name,
        reservation?.check_in,
        reservation?.check_out,
        reservation?.pms_id,
        reservation?.reservation_id,
        ...answers,
      ];
    });
  }, [questions, responses]);

  const answers = useMemo(() => {
    return responses.map((r) => {
      let answers = questions.map(
        (q) =>
          r.answers.find((qa) => qa.survey_question_id === q.id)?.answer || "",
      );
      return answers || [];
    });
  }, [questions, responses]);

  const formatData = (question) => {
    let answers = question.responses.answers || [];
    let formatted = Object.keys(answers).map((k) => {
      return [k, answers[k]];
    });

    return [["Answer", "Sum"], ...formatted];
  };

  const formatRangeData = (question, range) => {
    let answers = question.responses.answers || [];
    let formatted = new Array(range).fill(0).map((v, i) => {
      return [i + 1, answers[i] || 0];
    });

    return [["Answer", "Sum"], ...formatted];
  };

  const updateView = (view) => {
    setView(view);
  };

  const updateSelected = (idx) => {
    if (idx >= 0 && idx < survey.survey_results.length) {
      setView("details");
      setSelected(idx);
    }
  };

  const handleSMS = (reservation) => {
    reservationContext.setReservation(reservation);
  };

  const handleResult = async (result) => {
    await surveysContext.flagSurveyResult(result.survey_results_id);
    let survey_id = history.location.pathname.split("/")[2];
    await surveysContext.getSurveyAndResult(survey_id, startDate, endDate);
  }

  const chartRenderer = useCallback(
    (question) => {
      const options = {
        "multiple-choice": {
          legend: {
            textStyle: {
              color: "#666",
              fontName: "Lato",
              fontSize: 14,
              bold: false,
            },
          },
        },
        checkboxes: {
          title: "",
          bar: { groupWidth: "60%" },
          legend: { position: "none" },
          colors: ["#569946"],
          hAxis: {
            title: "",
            legend: {
              textStyle: {
                color: "#666",
                fontName: "Lato",
                fontSize: 14,
                bold: false,
              },
            },
            minValue: 0,
          },
          vAxis: {
            title: "",
            legend: {
              textStyle: {
                color: "#666",
                fontName: "Lato",
                fontSize: 14,
                bold: false,
              },
            },
          },
        },
        "range-5": {
          title: "",
          bar: { groupWidth: "60%" },
          legend: { position: "none" },
          hAxis: {
            title: "",
            legend: {
              textStyle: {
                color: "#666",
                fontName: "Lato",
                fontSize: 14,
                bold: false,
              },
            },
          },
          Axis: {
            title: "",
            legend: {
              textStyle: {
                color: "#666",
                fontName: "Lato",
                fontSize: 14,
                bold: false,
              },
            },
          },
        },
        "range-10": {
          title: "",
          bar: { groupWidth: "60%" },
          legend: { position: "none" },
          hAxis: {
            title: "",
            legend: {
              textStyle: {
                color: "#666",
                fontName: "Lato",
                fontSize: 14,
                bold: false,
              },
            },
          },
          Axis: {
            title: "",
            legend: {
              textStyle: {
                color: "#666",
                fontName: "Lato",
                fontSize: 14,
                bold: false,
              },
            },
          },
        },
      };

      return (
        <div className="chart-wrapper">
          {question.type === "multiple-choice" && (
            <Chart
              chartType="PieChart"
              data={formatData(question)}
              width="1000px"
              height="200px"
              options={options[question.type]}
            />
          )}

          {question.type === "checkboxes" && (
            <Chart
              chartType="BarChart"
              data={formatData(question)}
              width="1000px"
              height="200px"
              options={options[question.type]}
            />
          )}

          {question.type === "range-5" && (
            <Chart
              chartType="ColumnChart"
              data={formatRangeData(question, 5)}
              width="1000px"
              height="200px"
              options={options[question.type]}
            />
          )}

          {question.type === "range-10" && (
            <Chart
              chartType="ColumnChart"
              data={formatRangeData(question, 10)}
              width="1000px"
              height="200px"
              options={options[question.type]}
            />
          )}

          {question.type === "short-answer" && (
            <div className="chart">
              <ul className="uk-list uk-list-striped">
                {Object.keys(question.responses.answers)
                  .filter((answer) => !!answer)
                  .map((answer) => {
                    return <li>{answer}</li>;
                  })}
              </ul>
            </div>
          )}
        </div>
      );
    },
    [questions],
  );

  const answerRenderer = useCallback(
    (result) => {
      return questions.map((quiz) => {
        let data =
          quiz?.type === "checkboxes"
            ? result.answers.filter(
                (re) => re.survey_question_id.toString() === quiz.id.toString(),
              )
            : result.answers.find(
                (re) => re.survey_question_id.toString() === quiz.id.toString(),
              );

        if (data) {
          return (
            <div
              className="uk-card uk-card-small uk-card-default hms-property-card"
              key={quiz.survey_question_id}
            >
              <div className="guest-information"></div>
              <div className="uk-flex uk-flex-between">
                <div className="hms-property-card-body">
                  <div className="hms-property-card-content">
                    <h4 className="card-heading-small">{quiz?.title}</h4>
                    <div className="hms-small-note">{quiz?.help_text}</div>
                    <div>
                      {quiz?.type === "multiple-choice" && (
                        <MultipleChoice
                          options={quiz?.options}
                          answer={data?.answer}
                        />
                      )}
                      {quiz?.type === "short-answer" && (
                        <ShortAnswer
                          answer={data?.answer || data.custom_answer}
                        />
                      )}
                      {quiz?.type === "checkboxes" && (
                        <Checkboxes
                          options={quiz?.options}
                          answer={data?.map((a) => a.answer || a.custom_answer)}
                        />
                      )}
                      {quiz?.type === "range-5" && (
                        <Range question={quiz} type={5} answer={data?.answer} />
                      )}
                      {quiz?.type === "range-10" && (
                        <Range
                          question={quiz}
                          type={10}
                          answer={data?.answer}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }
      });
    },
    [survey.survey_results],
  );

  const listRenderer = (result, index) => {
    let { reservation } = result;
    return (
      <tr key={index}>
        <td className="hms-action-btn">
          <button
            className="uk-button"
            onClick={() => updateSelected(index)}
            uk-tooltip="Response Details"
          >
            <Image src="/images/detail.svg" />
          </button>
          {reservation.formatted_phone && reservation.formatted_phone.trim() && (
            <div className="uk-inline">
              <button
                className="uk-button"
                uk-toggle="target: #modal-guest-messages"
                onClick={() => handleSMS(reservation)}
                uk-tooltip="Send Text"
              >
                <Image src="/images/sms.svg" />
              </button>
            </div>
          )}
          <div class="uk-inline">
            <button
              class="uk-button"
              uk-tooltip="Mark Handled"
              title=""
              aria-describedby="uk-tooltip-755"
              aria-expanded="false"
              onClick={() => {
                handleResult(result);
              }}
            >
              <img
                data-src={
                  result.flagged
                    ? "/images/fulfilled.svg"
                    : "/images/unfulfilled.svg"
                }
                data-uk-img=""
                loading="lazy"
                src={
                  result.flagged
                    ? "/images/fulfilled.svg"
                    : "/images/unfulfilled.svg"
                }
              />
            </button>
          </div>
        </td>
        <td>
          <div>{moment(result.created_at).format("MM/DD/YYYY h:mm")}</div>
        </td>
        <td>{`${reservation?.first_name || ""} ${
          reservation?.last_name || ""
        }`}</td>
        <td>
          {moment(reservation?.check_in, "YYYY-MM-DD").format("MM/DD/YYYY")} -
          {moment(reservation?.check_out, "YYYY-MM-DD").format("MM/DD/YYYY")}
        </td>
        <td>{reservation?.pms_id || ""}</td>
        <td>{reservation?.reservation_id || ""}</td>
        {answers[index].map((answer) => 
          <td className="message">
            <textarea
              rows={1}
              value={answer}
              disabled
            />
          </td>
        )}
      </tr>
    );
  };

  return (
    <div id="surveys-detail-template">
      <div id="hms-page-title">
        <div>
          <div className="uk-align-left">
            <div className="uk-flex uk-flex-middle">
              <button
                type="button"
                className="uk-button hms-img-btn go-back-arrow-btn"
                onClick={handleBack}
              >
                <span uk-icon="icon: arrow-left; ratio: 1"></span>
              </button>
              <h1 className="uk-heading-small">{survey?.name}</h1>
            </div>
          </div>
          <div className="uk-align-right">
            <div className="uk-inline hms-date-picker uk-margin-small-right">
              <input
                className="uk-input"
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="uk-inline hms-date-picker uk-margin-small-right">
              <input
                className="uk-input"
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            {survey.survey_results_count > 0 && (
              <CSVLink
                data={data}
                filename={`${moment().unix()}_survey_response.csv`}
                headers={headers}
              >
                <button className="download">
                  <img src="/images/download.svg" uk-tooltip="Download CSV" />
                </button>
              </CSVLink>
            )}
          </div>
        </div>
      </div>
      <div id="hms-main-body">
        <ul
          className="survey-tabs uk-margin-small-bottom uk-flex-left"
          data-uk-tab
        >
          <li
            className="survey-tab uk-active"
            onClick={() => {
              updateView("overview");
            }}
          >
            <a href="#">Overview</a>
          </li>
          <li
            className="survey-tab"
            onClick={() => {
              updateView("list");
            }}
          >
            <a href="#">List</a>
          </li>
        </ul>

        {view === "overview" && (
          <div>
            {survey.survey_results_count == 0 ? (
              <div className="survey-no-response">
                No survey responses for this date range
              </div>
            ) : (
              questions.map((question) => {
                return (
                  question.responses.sum > 0 && (
                    <div>
                      <h4>{question.title}</h4>
                      <div>{question.responses.sum} Reponse(s)</div>
                      <div>{chartRenderer(question)}</div>
                    </div>
                  )
                );
              })
            )}
          </div>
        )}

        {view === "details" && (
          <div>
            <div className="survey-details-header">
              <div className="uk-align-left">
                <div className="uk-flex">
                  <button
                    type="button"
                    className="uk-button hms-img-btn go-back-arrow-btn"
                    onClick={() => {
                      setView("list");
                    }}
                  >
                    <span uk-icon="icon: arrow-left; ratio: 1"></span>
                  </button>
                  <div>
                    <div className="uk-margin-left">
                      <div className="secondary">
                        {moment(
                          survey.survey_results[selected].reservation?.check_in,
                        ).format("MMM DD, YYYY")}{" "}
                        -{" "}
                        {moment(
                          survey.survey_results[selected].reservation
                            ?.check_out,
                        ).format("MMM DD, YYYY")}
                      </div>
                      <div className="semi-bold-font">
                        {`${
                          survey.survey_results[selected].reservation
                            ?.first_name || ""
                        } ${
                          survey.survey_results[selected].reservation
                            ?.last_name || ""
                        }'s Response`}
                      </div>
                      <div className="secondary">
                        {survey.survey_results[selected].reservation?.pms_id}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-align-right">
                {!!reservation?.thread_id && (
                  <a href={`/messages/${reservation?.thread_id}`}>
                    <button className="uk-button hms-btn hms-red-btn">
                      {`Message ${reservation?.first_name || ""} ${
                        reservation?.last_name || ""
                      }`}
                    </button>
                  </a>
                )}
              </div>
            </div>
            {survey.survey_results_count == 0 ? (
              <div className="survey-no-response">
                No survey responses for this date range
              </div>
            ) : (
              answerRenderer(responses[selected])
            )}
          </div>
        )}

        {view === "list" && (
          <div>
            {survey.survey_results_count == 0 ? (
              <div className="survey-no-response">
                No survey responses for this date range
              </div>
            ) : (
              <div className="uk-card uk-card-small uk-card-default hms-form-card card-without-filters">
                <div className="uk-overflow-auto">
                  <table className="uk-table uk-table-small uk-table-hover uk-table-divider hms-table">
                    <thead>
                      <tr>
                        <th className="actions">Actions</th>
                        <th className="">Submitted At</th>
                        <th className="">Guest Name</th>
                        <th className="">Check-In - Check-Out</th>
                        <th className="">Property Id</th>
                        <th className="">Reservation Id</th>
                        {
                          questions?.map((q) => <th className="message">
                            {
                              q?.title.length > 40 ? `${q?.title.slice(0, 40)}...` : q?.title
                            }
                          </th>)
                        }
                      </tr>
                    </thead>
                    <tbody>{responses.map(listRenderer)}</tbody>
                  </table>
                </div>
                <div className="uk-margin-top">
                  <span>{responses.length || 0} Result(s)</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <GuestMessages />
    </div>
  );
};

export default Responses;
