// Import package components
import {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Image, Flex } from "uikit-react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import UIkit from "uikit";
import Papa from "papaparse";
import clsx from "clsx";

// Import base components
import { AppContexts } from "../../providers";

// Import common components
import PageTitle from "../common/PageTitle";
import Pagination from "../common/Pagination";
import RecordsPerPage from "../common/RecordsPerPage";
import TableLoading from "../common/Loading/table_loading";
import ReservationNumber from "../common/ReservationNumber";
import ReservationType from "../common/ReservationType";

import { host } from "../../http";

// Import guest components
import GuestAdd from "./guest_add";
import GuestMessages from "./guest_messages";
import GuestRate from "./guest_rate";
import GuestShare from "./guest_share";
import GuestDelete from "./delete";
import WorkOrderAdd from "../work_order/work_order_add";

import { Link } from "../../ui";
import { useCookies } from "react-cookie";
import GuestDetail from "./guest_detail";
import NoteAdd from "../notes/note-add";
import { useDeepCompareEffect } from "../../hook";

const presetOptions = [
  { value: "all", label: "All" },
  { value: "arriving", label: "Arriving Today" },
  { value: "tomorrow", label: "Arriving Tomorrow" },
  { value: "soon", label: "Arriving Next 3 Days" },
  { value: "week", label: "Arriving Next 7 Days" },
  { value: "custom", label: "Arriving Between" },
  { value: "departing", label: "Departing Today" },
  { value: "departing-tomorrow", label: "Departing Tomorrow" },
  { value: "departing-soon", label: "Departing Next 3 Days" },
  { value: "departing-week", label: "Departing Next 7 Days" },
  { value: "departing-on", label: "Departing Between" },
  { value: "current", label: "Currently Staying" },
];

const defaultCriteria = {
  search_term: "",
  filters: "all",
  preset: "soon",
  start_date: moment().format("YYYY-MM-DD"),
  end_date: moment().add(3, "days").format("YYYY-MM-DD"),
  end_type: "check_in",
  page: 0,
  checked_in: false,
  checked_out: false,
  categories: "",
};

const parseQueryString = (queryString) => {
  return {
    ...defaultCriteria,
    ...Object.fromEntries(new URLSearchParams(queryString)),
  };
};

const Guests = () => {
  const reservationContext = useContext(AppContexts.ReservationsContext);
  const { getThreadDetail, setThreadDetail } = useContext(
    AppContexts.InboxContext,
  );
  const { user } = useContext(AppContexts.AuthContext);
  const settingsContext = useContext(AppContexts.SettingsContext);
  const workOrderContext = useContext(AppContexts.WorkOrderContext);
  const { scheduledMsgs, getScheduler } = useContext(
    AppContexts.SchedulerContext,
  );
  // const notesContext = useContext(AppContexts.NotesContext);
  const [cookies] = useCookies(["records-per-page"]);
  const { "records-per-page": recordsPerPage } = cookies;
  const [selectedGuests, setMultipleGuests] = useState([]);
  const [grId, setGrId] = useState(undefined);
  const [objGuest, setObjGuest] = useState({});
  const { register, getValues, setValue, watch } = useForm();
  const location = useLocation();
  const history = useHistory();
  const [criteria, setCriteria] = useState(() => {
    return location.search
      ? parseQueryString(location.search)
      : defaultCriteria;
  });
  const inputRef = useRef();

  const showMsg = (msg, reservation) => {
    if (!msg.active || msg.deleted) {
      return false;
    }

    let msgTags = msg.tags || [];
    let resTags = reservation.tags || [];

    if (msgTags.length > 0) {
      return !!msgTags.filter(
        (t1) => !!resTags.find((t2) => t1.id === t2.id),
      )[0];
    }

    return true;
  };

  const scheduled = useMemo(() => {
    return (scheduledMsgs || []).sort((prev, next) =>
      next?.updated_at?.localeCompare(prev?.updated_at),
    );
  }, [scheduledMsgs]);

  const tags = useMemo(
    () => settingsContext.settings.tags || [],
    [settingsContext.settings],
  );
  const preset = useMemo(() => getValues("preset"), [watch("preset")]);

  useEffect(() => {
    Object.keys(criteria).forEach((key) => {
      setValue(key, criteria[key]);
    });
    document.title = "Guests - RueBaRue";
    settingsContext.get(["tags"]);
    getScheduler();

    return () => {
      setCriteria(defaultCriteria);
    };
  }, []);

  const updateQueryParams = (newCriteria) => {
    const searchParams = new URLSearchParams(newCriteria).toString();
    history.replace(`/guests?${searchParams}`);
  };

  const convertedReservation = useMemo(() => {
    if (!reservationContext.listReservations) {
      return [];
    } else {
      return reservationContext.listReservations.map((reservation) => {
        let rentalTag = reservation?.tags
          ?.filter((item) => item.type === "rental")
          .sort((prev, next) => prev.name.localeCompare(next.name));
        let destinationTag = reservation?.tags
          ?.filter((item) => item.type === "destination")
          .sort((prev, next) => prev.name.localeCompare(next.name));
        let guestTag = reservation?.tags
          ?.filter((item) => item.type === "guest_reservation")
          .sort((prev, next) => prev.name.localeCompare(next.name));
        let filteredDestinationTag = destinationTag?.filter((item) => {
          if (!rentalTag.find((tag) => tag.name === item.name)) {
            return true;
          }
          return false;
        });
        filteredDestinationTag?.forEach((item) => {
          rentalTag.push(item);
        });
        let filteredGuestTag = guestTag?.filter((item) => {
          if (!rentalTag.find((tag) => tag.name === item.name)) {
            return true;
          }
          return false;
        });
        filteredGuestTag?.forEach((item) => {
          rentalTag.push(item);
        });
        return {
          ...reservation,
          tags: rentalTag,
          tag_array: rentalTag,
        };
      });
    }
  }, [reservationContext.listReservations]);

  useEffect(() => {
    setMultipleGuests([]);
  }, [convertedReservation.length]);

  useEffect(() => {
    switch (getValues("preset")) {
      case "arriving":
        setValue("start_date", moment().format("YYYY-MM-DD"));
        setValue("end_date", moment().format("YYYY-MM-DD"));
        break;
      case "departing":
        setValue("start_date", moment().format("YYYY-MM-DD"));
        setValue("end_date", moment().format("YYYY-MM-DD"));
        break;
      case "tomorrow":
        setValue("start_date", moment().add(1, "days").format("YYYY-MM-DD"));
        setValue("end_date", moment().add(1, "days").format("YYYY-MM-DD"));
        break;
      case "departing-tomorrow":
        setValue("start_date", moment().add(1, "days").format("YYYY-MM-DD"));
        setValue("end_date", moment().add(1, "days").format("YYYY-MM-DD"));
        break;
      case "soon":
        setValue("start_date", moment().format("YYYY-MM-DD"));
        setValue("end_date", moment().add(3, "days").format("YYYY-MM-DD"));
        break;
      case "departing-soon":
        setValue("start_date", moment().format("YYYY-MM-DD"));
        setValue("end_date", moment().add(3, "days").format("YYYY-MM-DD"));
        break;
      case "week":
        setValue("start_date", moment().format("YYYY-MM-DD"));
        setValue("end_date", moment().add(7, "days").format("YYYY-MM-DD"));
        break;
      case "departing-week":
        setValue("start_date", moment().format("YYYY-MM-DD"));
        setValue("end_date", moment().add(7, "days").format("YYYY-MM-DD"));
        break;
      case "custom":
        break;
      case "departing-on":
        break;
      case "current":
        setValue("start_date", moment().format("YYYY-MM-DD"));
        setValue("end_date", moment().format("YYYY-MM-DD"));
        break;
      case "all":
        setValue("start_date", "2000-01-01");
        setValue("end_date", "2050-12-31");
        break;
      default:
        break;
    }
  }, [watch("preset")]);

  useDeepCompareEffect(() => {
    reservationContext.searchReservation(criteria);
    updateQueryParams(criteria);
  }, [criteria]);

  useEffect(() => {
    setCriteria((prev) => {
      return {
        ...prev,
        start_date: getValues("start_date"),
        end_date: getValues("end_date"),
        filters: getValues("filters"),
        search_term: getValues("search_term").trim(),
        preset: getValues("preset"),
        per_page: recordsPerPage,
      };
    });
  }, [
    watch("preset"),
    watch("start_date"),
    watch("end_date"),
    watch("filters"),
    watch("search_term"),
    recordsPerPage,
  ]);

  const selectedAny = useMemo(() => {
    return selectedGuests.length > 0 ? true : false;
  }, [selectedGuests]);

  const handleStartDate = async (e) => {
    await setValue("start_date", e.target.value);
    if (moment(e.target.value) > moment(getValues("end_date"))) {
      setValue("end_date", e.target.value);
    }
    if (getValues("preset") !== "custom" && getValues("preset") !== "departing-on") {
      setValue("preset", "custom");
    }
  };

  const handleEndDate = async (e) => {
    await setValue("end_date", e.target.value);
    if (moment(e.target.value) < moment(getValues("start_date"))) {
      setValue("start_date", e.target.value);
    }
    if (getValues("preset") !== "custom" && getValues("preset") !== "departing-on") {
      setValue("preset", "custom");
    }
  };

  const handleAddGuest = useCallback(() => {
    reservationContext.setIsNew(true);
    reservationContext.initReservation();
  }, []);

  const handleImport = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    // Store the current input value
    Papa.parse(fileObj, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        let data = results.data.map((data) => ({
          reservation_id: data.ReservationId,
          pms_id: data.PropertyId,
          first_name: data.FirstName,
          last_name: data.LastName,
          check_in: new Date(data.CheckIn),
          check_out: new Date(data.CheckOut),
          email: data.Email,
          phone: data.Phone,
          door_code: data.DoorCode,
        }));

        let chunkSize = 10;
        let chunks = [];
        for (let i = 0; i < data.length; i += chunkSize) {
          chunks.push(data.slice(i, i + chunkSize));
        }

        const promises = chunks.map((chunk) =>
          reservationContext.importReservations(chunk),
        );

        try {
          const results = await Promise.all(promises);
          const totalDuplicates = results.reduce((acc, result) => {
            return acc + (result?.exists?.length || 0);
          }, 0);

          // Show notification based on duplicates
          if (totalDuplicates > 0) {
            UIkit.notification(
              `${totalDuplicates} records have existing Reservation ID.`,
              "error",
            );
          } else {
            UIkit.notification("Guest Added Successfully", "success");
          }

          // Reset input field and trigger event
          event.target.value = "";
          const newEvent = new Event("input", { bubbles: true });
          event.target.dispatchEvent(newEvent);
        } catch (error) {
          // Handle any errors that occur during the requests
          console.error("Error importing reservations:", error);
          UIkit.notification("Error importing reservations.", "error");
        }
      },
    });
  };

  const checkedAll = useMemo(() => {
    return tags.map((tagValue) => {
      return selectedGuests.every((guest) =>
        guest.tag_array?.find(
          (tag) => tag.id.toString() === tagValue.id.toString(),
        ),
      );
    });
  }, [selectedGuests, convertedReservation]);

  const handleMultiTag = (event, tagValue) => {
    let guests;
    if (event.target.checked) {
      guests = selectedGuests.filter(
        (guest) => !guest.tag_array?.find((tag) => tag.id == tagValue.id),
      );
      guests = guests.map((guest) => {
        return guest.tag_array
          ? {
              ...guest,
              tags: [...guest.tag_array, tagValue],
            }
          : {
              ...guest,
              tags: [tagValue],
            };
      });
    } else {
      guests = selectedGuests.filter((guest) =>
        guest.tags?.find((tag) => tag.id == tagValue.id),
      );
      guests = guests.map((guest) => {
        return {
          ...guest,
          tags: guest.tags.filter((tag) => tag.id != tagValue.id),
        };
      });
    }
    guests.forEach(async (guest) => {
      await reservationContext.updateReservation(
        guest.guest_reservation_id,
        guest,
      );
    });
  };

  const handleDelete = (reservation) => {
    setObjGuest(reservation);
  };

  const handleDeleteMultiple = () => {
    setObjGuest(selectedGuests);
  };

  const handleEdit = useCallback((index, reservation) => {
    reservationContext.setIsNew(false);
    reservationContext.editReservation(reservation);
  }, []);

  const handleDetail = useCallback(async (reservation) => {
    await reservationContext.setReservation(reservation);
    await setThreadDetail({});
    await reservationContext.setHistory([]);
    await reservationContext.setGuestBook([]);
    reservationContext.setIsLoading(true);
    await Promise.all([
      reservation.thread_id
        ? getThreadDetail(reservation.thread_id)
        : reservationContext.getReservationByPhone(reservation),
      reservationContext.getGuestBook(reservation.guest_reservation_id),
      reservationContext.getSurvey(reservation.guest_reservation_id),
      reservationContext.getFivestar(reservation.guest_reservation_id),
    ]);
    reservationContext.setIsLoading(false);
  }, []);

  const handleShare = (index, reservation) => {
    reservationContext.setReservation(reservation);
  };

  const handlePageChange = useCallback(
    (page) => {
      setCriteria((prev) => {
        return {
          ...prev,
          page: page,
        };
      });
    },
    [criteria],
  );

  const handleAddWorkOrder = useCallback((reservation_id) => {
    workOrderContext.setIsNew(true);
    workOrderContext.initWorkOrder();
    setGrId(reservation_id);
  }, []);

  const handleSMS = (reservation) => {
    reservationContext.setReservation(reservation);
  };

  const handleScheduledMsg = (guest_reservation_id, flag, msg_id) => {
    reservationContext
      .sendScheduledMessage(guest_reservation_id, msg_id)
      .then((res) => {
        if (res && res.status) {
          const updatedReservations = reservationContext.listReservations.map(
            (reservation) => {
              if (
                +reservation.guest_reservation_id ===
                +res.status.guest_reservation_id
              ) {
                (reservation.sent_scheduled_messages || []).push(res.status);
                return reservation;
              }
              return reservation;
            },
          );
          reservationContext.setReservations(updatedReservations);
          UIkit.notification("Scheduled message sent successfully", "success");
        } else {
          UIkit.notification(res.error, "danger");
        }
      });
  };

  const handleChangeTags = (tag, selected, destReservations) => {
    destReservations.forEach((reservation) => {
      let updatedReservation = {};
      if (selected) {
        updatedReservation = {
          ...reservation,
          tags: [
            ...(reservation?.tag_array || []).filter(
              (item) => item.type === "guest_reservation",
            ),
            tag,
          ],
        };
      } else {
        updatedReservation = {
          ...reservation,
          tags: (reservation.tag_array || []).filter(
            (existing) =>
              existing.id !== tag.id && existing.type === "guest_reservation",
          ),
        };
      }
      reservationContext.updateReservation(
        reservation.guest_reservation_id,
        updatedReservation,
      );
    });
  };

  const checkTag = (source, dest) => {
    const sourceMap = new Map();
    for (const sourceElem of source) {
      sourceMap.set(sourceElem.id, true);
    }
    for (const destElem of dest) {
      if (sourceMap.has(destElem.id)) return true;
    }
    return false;
  };

  const hasFlag = (reservation, flag) => {
    return !!(reservation.flags || []).filter((f) => f.name === flag)[0];
  };

  const searchTerm = useMemo(
    () => getValues("search_term"),
    [watch("search_term")],
  );

  const missingFields = (reservation) => {
    let missing = [];
    if (!reservation.email) missing.push("Email");
    if (!reservation.phone) missing.push("Phone");
    if (!reservation.destination_id) missing.push("Area Guide Not Attached");
    return missing.join(", ");
  };

  const handleAutoSend = (evt, reservation) => {
    reservationContext.updateReservation(reservation.guest_reservation_id, {
      ...reservation,
      door_code: reservation.manual_door_code,
      tags: (reservation.tags || []).filter(
        (item) => item.type === "guest_reservation",
      ),
      disabled: !evt.target.checked,
    });
  };

  // const handleAddNote = (reservation) => {
  //   notesContext.getNoteByItem(reservation.guest_id || reservation.guest_reservation_id, 'guest');
  //   reservationContext.setReservation(reservation);
  // }

  const reservationRenderer = (reservation, index) => (
    <tr key={index}>
      <td>
        {reservation.category !== "CXL" ? (
          <div>
            <label htmlFor="checkboxGuest3" className="checkboxGuest">
              <input
                type="checkbox"
                id="checkboxGuest3"
                checked={selectedGuests.includes(reservation)}
                onChange={(evt) =>
                  evt.target.checked
                    ? setMultipleGuests([...selectedGuests, reservation])
                    : setMultipleGuests(
                        selectedGuests.filter(
                          (guest) =>
                            guest.guest_reservation_id !==
                            reservation.guest_reservation_id,
                        ),
                      )
                }
              />
            </label>
          </div>
        ) : (
          <div></div>
        )}
      </td>
      <td>
        <div className="autosend-checkbox">
          {reservation.category !== "CXL" ? (
            <div>
              <label
                htmlFor={`auto-send-${reservation.guest_reservation_id}`}
                className="setting-switch"
              >
                <input
                  type="checkbox"
                  id={`auto-send-${reservation.guest_reservation_id}`}
                  onChange={(e) => handleAutoSend(e, reservation)}
                  checked={!reservation.disabled}
                />
                <span className="setting-slider setting-round"></span>
              </label>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </td>
      <td className="hms-guests-column">
        <div className="guests-secondary">
          {moment.utc(reservation.check_in).format("MMM DD, YYYY")} -{" "}
          {moment.utc(reservation.check_out).format("MMM DD, YYYY")}
        </div>
        <div className="semi-bold-font">
          {reservation.first_name} {reservation.last_name}
        </div>
        <div className="secondary">{reservation.formatted_phone}</div>
        <div className="secondary">{reservation.email}</div>
        {reservation.five_star_results && (
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-review">
              {[...Array(Math.round(reservation.five_star_results))].map(
                (value, idx) => (
                  <span
                    key={`rating_${idx}`}
                    uk-icon="icon: star; ratio: 0.8"
                    className="hms-rated-star"
                  ></span>
                ),
              )}
              {[...Array(5 - Math.round(reservation.five_star_results))].map(
                (value, idx) => (
                  <span
                    key={`non-rating_${idx}`}
                    uk-icon="icon: star; ratio: 0.8"
                  ></span>
                ),
              )}
            </div>
          </div>
        )}
        <div className="hms-booking-tag uk-margin-small-top">
          <ReservationType reservation={reservation} />
        </div>

        <div className="property-id-msg">
          {!reservation.formatted_phone && (
            <div>
              <Image src="/images/warning.svg" />
              <span className="guest-missing" style={{ marginLeft: "5px" }}>
                Mobile Phone is Missing
              </span>
            </div>
          )}
          {!reservation.email && (
            <div>
              <Image className="guest-missing" src="/images/warning.svg" />
              <span style={{ marginLeft: "5px" }}>Email is Missing</span>
            </div>
          )}
          {/* reservation.rental_id && reservation.destination_id && (
            <a
              href={`https://guide.ruebarue.com/destination/${reservation.destination_id}`}
              target="_blank"
              className="guest-missing-property-guide"
            >
              <Image src="/images/missing-home-guide.svg" />
              <span style={{ marginLeft: "5px" }}>
                {reservation.destination_name}
              </span>
            </a>
          ) */}
        </div>
      </td>
      <td className="hms-guests-column">
        <div>{reservation.pms_id}</div>
        <ReservationNumber
          hostID={reservation?.host_id}
          pms={user?.details?.pms}
          resevationID={reservation?.reservation_id}
          nativeID={reservation?.native_id}
        />
        <div className="guests-secondary">{reservation.source}</div>

        {/* <div>{reservation?.reservation_id}</div> */}

        <div className="property-id-msg">
          {(user?.details?.pms === "Streamline" ||
            user?.details?.pms === "LiveRez" ||
            user?.details?.pms === "Hostaway") &&
            !reservation.contract_signed && (
              <div>
                <Image src="/images/contract-not-signed.svg" />
                <span style={{ marginLeft: "5px" }}>Contract Not Signed</span>
              </div>
            )}
          {(user?.details?.pms === "Streamline" ||
            user?.details?.pms === "LiveRez") &&
            parseFloat(reservation.balance) > 0 && (
              <div>
                <Image src="/images/balance-pending.svg" />
                <span style={{ marginLeft: "5px" }}>Balance Pending</span>
              </div>
            )}
          {!!reservation.rental_id && reservation.rental_suspended && (
            <div>
              <Image src="/images/warning.svg" />
              <span className="guest-missing" style={{ marginLeft: "5px" }}>
                Home Guide Deleted{" "}
                <img
                  className="info-icon"
                  alt="info.icon"
                  src="/images/info-icon.svg"
                  uk-tooltip="Contact RueBaRue support to undelete the home guide."
                />
              </span>
            </div>
          )}
          {!reservation.rental_id && (
            <div>
              <Image src="/images/warning.svg" />
              <span className="guest-missing" style={{ marginLeft: "5px" }}>
                Home Guide Missing{" "}
                <img
                  className="info-icon"
                  alt="info.icon"
                  src="/images/info-icon.svg"
                  uk-tooltip="Home Guide for this Property ID is missing. Guests will not receive automated messages"
                />
              </span>
            </div>
          )}
          {!!reservation.rental_id && !reservation.rental_suspended && (!reservation.destination_id || reservation.destination_id == 0) && (
            <div>
              <Image src="/images/warning.svg" />
              <span className="guest-missing" style={{ marginLeft: "5px" }}>
                Area Guide Not Attached{" "}
                <img
                  className="info-icon"
                  src="/images/info-icon.svg"
                  alt="info"
                  uk-tooltip="Area Guide not attached to the Home Guide for this Property ID"
                />
              </span>
            </div>
          )}
        </div>
      </td>
      <td className="secondary">
        {reservation.manual_door_code || reservation.door_code}
      </td>
      <td className="hms-add-tag">
        <div className="uk-flex uk-flex-top guest-schedule-msg">
          <div>
            <button className="uk-button">
              <img src="/images/tag.svg" />
            </button>
            <div data-uk-dropdown="mode: click" className="guest-tags-dropdown">
              <ul className="uk-nav uk-dropdown-nav tags-selector">
                {tags.map((tag, index) =>
                  reservation.tag_array?.findIndex(
                    (item) =>
                      item.id === tag.id &&
                      (item.type === "rental" || item.type === "destination"),
                  ) >= 0 ? (
                    <></>
                  ) : (
                    <li key={`${reservation.reservation_id}_${index}`}>
                      <label>
                        <input
                          className="uk-checkbox"
                          type="checkbox"
                          checked={
                            reservation.tag_array?.findIndex(
                              (item) => item.id === tag.id,
                            ) >= 0
                          }
                          onChange={(event) =>
                            handleChangeTags(tag, event.target.checked, [
                              reservation,
                            ])
                          }
                        />
                        {tag.name}
                      </label>
                    </li>
                  ),
                )}
              </ul>
            </div>
          </div>
          <div className="hms-guest-tags">
            <div className="guest-tags">
              {reservation.tag_array?.map((tag, idx) => (
                <div
                  className={clsx("uk-inline", {
                    rental: tag.type === "rental",
                    destination: tag.type === "destination",
                    reservation: tag.type === "guest_reservation",
                  })}
                  key={idx}
                >
                  {tag.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </td>
      <td className="hms-guests-column">
        <div className="guest-schedule-msg">
          {reservation.category !== "CXL" ? (
            <div>
              {scheduled?.map((item, i) => {
                if (!showMsg(item, reservation)) {
                  return;
                }

                let msg_time =
                  Math.abs(item.send_day) === 99999
                    ? "Not Scheduled"
                    : Math.abs(item.send_day) === 99998
                      ? `Email Upon Booking at ${item.send_time}`
                      : Math.abs(item.send_day) <= 99997 &&
                          Math.abs(item.send_day) >= 99990
                        ? `${99997 - Math.abs(item.send_day)} Day(s) ${
                            item.reference_direction
                          } 
                  ${item.reference_point
                    .toLowerCase()
                    .replace(/\b\w/g, (s) => s.toUpperCase())} at ${
                    item.send_time
                  }`
                        : `${Math.abs(item.send_day)} Days ${
                            item.reference_direction
                          } Check-${item.reference_point} at ${item.send_time}`;

                let sent_check;
                //            let msg_time;
                if (
                  !reservation?.sent_scheduled_messages?.length ||
                  !reservation?.sent_scheduled_messages
                ) {
                  sent_check = 0;
                  //              msg_time = `${item.send_day} Day(s) ${item.reference_direction} Check-${item.reference_point} at ${item.send_time};`;
                } else {
                  let sent_message = reservation.sent_scheduled_messages.find(
                    (mes) => mes.msg_id == item.scheduled_message_id,
                  );
                  if (sent_message) {
                    sent_check = 1;
                    msg_time = moment
                      .utc(sent_message.created_at)
                      .local()
                      .format("YYYY-MM-DD hh:mm");
                  } else {
                    //                msg_time = `${item.send_day} Day(s) ${item.reference_direction} Check-${item.reference_point} at ${item.send_time};`;
                  }
                }

                return (
                  <div className="uk-flex uk-flex-middle" key={i}>
                    {(item.sms === true && !reservation.phone) ||
                    (item.email && !reservation.email) ? (
                      <div
                        className="guest-schedule-icon"
                        uk-tooltip={
                          item.sms === true && !reservation.phone
                            ? "Mobile Phone is Missing"
                            : "Email is Missing"
                        }
                      >
                        <Image src="/images/missing-phone-email.svg" />
                      </div>
                    ) : (
                      <div className="guest-schedule-icon">
                        {sent_check ? (
                          <Image src="/images/check.svg" />
                        ) : (
                          <Image src="/images/send.svg" />
                        )}
                      </div>
                    )}

                    {item.sms === true && (
                      <div
                        className="guest-schedule-icon"
                        uk-tooltip="Text Message"
                      >
                        <Image src="/images/text-tag.svg" />
                      </div>
                    )}
                    {item.email === true && (
                      <div
                        className="guest-schedule-icon"
                        uk-tooltip="Email Message"
                      >
                        <Image src="/images/email-tag.svg" />
                      </div>
                    )}
                    <div
                      className={clsx("hms-sms-send-text", {
                        disabled: item.email
                          ? !reservation.email
                          : !reservation.phone,
                      })}
                      uk-tooltip={`title: <b>${item.name}:</b> <br> ${msg_time}; delay: 300;`}
                      onClick={() =>
                        !(item.email
                          ? !reservation.email
                          : !reservation.phone) &&
                        handleScheduledMsg(
                          reservation.guest_reservation_id,
                          index * scheduled.length + i,
                          item.scheduled_message_id,
                        )
                      }
                    >
                      {+reservationContext.sendingMsg?.reservation_id ===
                        +reservation.guest_reservation_id &&
                      +reservationContext.sendingMsg?.msg_id ===
                        +item.scheduled_message_id
                        ? "Sending "
                        : sent_check
                          ? "Resend "
                          : "Send "}
                      {item.name}
                      {+reservationContext.sendingMsg?.reservation_id ===
                        +reservation.guest_reservation_id &&
                        +reservationContext.sendingMsg?.msg_id ===
                          +item.scheduled_message_id &&
                        "..."}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </td>
      <td className="hms-action-btn">
        <div className="uk-flex uk-flex-top more-menu">
          {reservation.category !== "CXL" ? (
            <div className="actions">
              {reservation.formatted_phone &&
                reservation.formatted_phone.trim() && (
                  <div className="uk-inline">
                    <button
                      className="uk-button"
                      uk-toggle="target: #modal-guest-messages"
                      onClick={() => handleSMS(reservation)}
                      uk-tooltip="Send Text"
                    >
                      <Image src="/images/sms.svg" />
                    </button>
                  </div>
                )}
              {/* <div className="uk-inline">
                <button
                  className="uk-button"
                  uk-toggle="target: #modal-guest-note"
                  onClick={() => handleAddNote(reservation)}
                  uk-tooltip="Notes"
                >
                  {
                    reservation?.notes?.length > 0
                    ? (<Image src="/images/notes-filled.svg" />)
                    : (<Image src="/images/notes.svg" />)
                  }
                </button>
              </div> */}
              <div className="uk-inline">
                <button
                  className="uk-button"
                  uk-toggle="target: #modal-guest-add"
                  onClick={() => handleEdit(index, reservation)}
                  uk-tooltip="Edit"
                >
                  <Image src={`/images/edit.svg`} />
                </button>
              </div>
              <div className="uk-inline">
                <button
                  className="uk-button"
                  uk-toggle="target: #modal-guest-detail"
                  onClick={() => handleDetail(reservation)}
                  uk-tooltip="Detail"
                >
                  <Image src="/images/detail.svg" />
                </button>
              </div>
              <div className="uk-inline">
                <button className="uk-button">
                  <Image src="/images/more.svg" />
                </button>
                <div
                  className="uk-navbar-dropdown"
                  data-uk-dropdown="pos: top-right"
                >
                  <ul class="uk-nav uk-navbar-dropdown-nav">
                    {!!reservation.rental_id && !reservation.rental_suspended && (
                      <li>
                        <a
                          href={`${host}/v2/reservations/guestbook/${reservation.guest_reservation_id}`}
                          target="_blank"
                        >
                          <Image src="/images/guide.svg" /> Guestbook
                        </a>
                      </li>
                    )}
                    <li
                      uk-toggle="target: #modal-guest-share"
                      onClick={() => handleShare(index, reservation)}
                    >
                      <a>
                        <Image src="/images/share.svg" /> Share
                      </a>
                    </li>
                    <li
                      uk-toggle="target: #work-order-add"
                      onClick={() =>
                        handleAddWorkOrder(reservation.guest_reservation_id)
                      }
                    >
                      <a>
                        <Image src="/images/workorder.svg" /> Work Order
                      </a>
                    </li>
                    {!!reservation.rental_id && !reservation.rental_suspended ? (
                      <li>
                        <a
                          href={`${host}/v2/reservations/guestbook/${reservation.guest_reservation_id}/print`}
                          target="_blank"
                        >
                          <Image src="/images/print.svg" /> Print
                        </a>
                      </li>
                    ) : null}
                    <li
                      uk-toggle="target: #guest-delete"
                      onClick={() => handleDelete(reservation)}
                    >
                      <a>
                        <Image src="/images/delete.svg" /> Delete
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="actions">
                {reservation.formatted_phone &&
                  reservation.formatted_phone.trim() && (
                    <div className="uk-inline">
                      <button
                        className="uk-button"
                        uk-toggle="target: #modal-guest-messages"
                        onClick={() => handleSMS(reservation)}
                        uk-tooltip="Send Text"
                      >
                        <Image src="/images/sms.svg" />
                      </button>
                    </div>
                  )}
                <div className="uk-inline">
                  <button
                    className="uk-button"
                    uk-toggle="target: #modal-guest-detail"
                    onClick={() => handleDetail(reservation)}
                    uk-tooltip="Detail"
                  >
                    <Image src="/images/detail.svg" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </td>
    </tr>
  );

  return (
    <>
      <section id="view-add-guests">
        <PageTitle title="Guests" />

        <div id="hms-main-body">
          <div className="uk-flex uk-flex-between uk-flex-top">
            <div className="hms-guest-btn">
              <button
                className="uk-button hms-btn hms-red-btn"
                uk-toggle="target: #modal-guest-add"
                onClick={handleAddGuest}
              >
                <span uk-icon="icon: plus; ratio: 0.7"></span> Guest
              </button>
              <span>
                <button
                  className="uk-button hms-btn hms-white-btn"
                  disabled={!selectedAny}
                >
                  <span uk-icon="icon: tag; ratio: 0.7"></span> Tag
                </button>
                <div data-uk-dropdown="mode: click">
                  <ul className="uk-nav uk-dropdown-nav tags-selector">
                    {tags.map((tagValue, index) => (
                      <li key={`taglist_${index}`}>
                        <label>
                          <input
                            className="uk-checkbox"
                            type="checkbox"
                            checked={checkedAll[index]}
                            onChange={(event) =>
                              handleMultiTag(event, tagValue)
                            }
                          />
                          {tagValue.name}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </span>
              <button
                className="uk-button hms-btn hms-white-btn"
                uk-toggle="target: #guest-delete"
                disabled={!selectedAny}
                onClick={handleDeleteMultiple}
              >
                <span uk-icon="icon: trash; ratio: 0.7"></span> Delete
              </button>
              <input
                style={{ display: "none" }}
                ref={inputRef}
                type="file"
                onInput={handleImport}
                accept=".csv"
              />
              <button
                className="uk-button hms-btn hms-white-btn"
                onClick={() => inputRef.current.click()}
              >
                <span uk-icon="icon: arrow-up; ratio: 1"></span> Import
              </button>
              <a
                href="/samples/guest-import-sample.csv"
                style={{ color: "black" }}
                target="_blank"
              >
                <img
                  className="info-icon"
                  src="/images/info-icon.svg"
                  alt="info"
                  uk-tooltip="Click to download sample comma separated file (CSV) to bulk import guest bookings"
                />
              </a>
            </div>
          </div>
          <div className="uk-card uk-card-small uk-card-default hms-form-card">
            <div className="uk-overflow-auto">
              <div className="uk-flex uk-flex-between uk-flex-top guest-form-top-filters">
                <div>
                  <form
                    action=""
                    className="uk-form uk-flex uk-flex-middle hms-form"
                  >
                    <div className="uk-form-controls uk-margin-small-right">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select
                          className="uk-select"
                          {...register("preset")}
                          disabled={reservationContext.flagIsProcessing}
                        >
                          {presetOptions.map((val, index) => (
                            <option value={val.value} key={index}>
                              {val.label}
                            </option>
                          ))}
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span>
                            {
                              presetOptions.find(
                                (option) => option.value === preset,
                              )?.label
                            }
                          </span>
                          <span
                            uk-icon="icon: chevron-down"
                            className="hms-align-right"
                          ></span>
                        </button>
                      </div>
                    </div>
                    <div className="uk-inline hms-date-picker">
                      <input
                        type="date"
                        id="guestDateStart"
                        {...register("start_date")}
                        disabled={false}
                        onChange={handleStartDate}
                      />
                    </div>
                    <div className="uk-inline hms-date-picker">
                      <input
                        type="date"
                        id="guestDateEnd"
                        {...register("end_date")}
                        disabled={false}
                        onChange={handleEndDate}
                      />
                    </div>
                    <div className="uk-inline">
                      <div uk-form-custom="target: > * > span:first-child">
                        <select {...register("filters")}>
                          <option value="all">Guest Bookings</option>
                          <option value="owners">Owners Bookings</option>
                          <option value="cancelled">Cancelled Bookings</option>
                          <option value="blocks">Maintenance Blocks</option>
                          <option value="no-property">
                            Missing Home Guide
                          </option>
                          <option value="no-code">No Door Code</option>
                          <option value="no-phone">No Phone Number</option>
                          <option value="no-email">No Email Address</option>
                          {(user?.details?.pms === "Streamline" ||
                            user?.details?.pms === "LiveRez") && (
                            <>
                              <option value="not-signed">
                                Contract Not Signed
                              </option>
                              <option value="balance-pending">
                                Balance Pending
                              </option>
                            </>
                          )}
                          <option value="rated">Rated</option>
                          <option value="check-in">Checked In</option>
                          <option value="check-out">Checked Out</option>
                        </select>
                        <button
                          className="uk-button uk-button-default"
                          type="button"
                          tabIndex="-1"
                        >
                          <span></span>
                          <span uk-icon="icon: chevron-down"></span>
                        </button>
                      </div>
                    </div>
                    <div className="uk-inline">
                      <div className="uk-search uk-search-default">
                        {searchTerm && searchTerm !== "" ? (
                          <a
                            className="uk-form-icon-flip uk-form-icon"
                            uk-icon="icon: close"
                            onClick={() => setValue("search_term", "")}
                          ></a>
                        ) : (
                          <span
                            className="uk-search-icon-flip"
                            uk-search-icon="true"
                          />
                        )}
                        <input
                          className="uk-search-input"
                          type="search"
                          placeholder="Name or Property ID"
                          {...register("search_term")}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <table className="uk-table uk-table-hover uk-table-divider hms-table">
                <thead>
                  <tr>
                    <th className="select-item">
                      <label
                        htmlFor="checkboxGuestAll"
                        className="checkboxGuest"
                      >
                        <input
                          type="checkbox"
                          id="checkboxGuestAll"
                          onChange={(evt) =>
                            evt.target.checked
                              ? setMultipleGuests(convertedReservation)
                              : setMultipleGuests([])
                          }
                        />
                      </label>
                    </th>
                    <th className="uk-table-shrink auto-send">
                      Auto
                      <br />
                      Send{" "}
                      <img
                        className="info-icon"
                        src="/images/info-icon.svg"
                        alt="info"
                        uk-tooltip="Enable or disable automated scheduled messages"
                      />
                    </th>
                    <th className="uk-table-expand">
                      CHECK-IN & OUT/ NAME/EMAIL/PHONE
                    </th>
                    <th className="uk-width-320">
                      PROPERTY ID/RESERVATION NUMBER/BOOKING SOURCE
                    </th>
                    <th className="uk-width-115 nowrap">
                      DOOR
                      <br />
                      CODE{" "}
                      <img
                        className="info-icon"
                        src="/images/info-icon.svg"
                        alt="info"
                        uk-tooltip="Available as [DoorCode] macro"
                      />
                    </th>
                    <th className="uk-table-expand tags">Tags</th>
                    <th className="uk-table-250">Scheduled Messages</th>
                    <th className="actions">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {reservationContext.flagIsProcessing == true ? (
                    TableLoading(8)
                  ) : convertedReservation.length > 0 ? (
                    convertedReservation.map(reservationRenderer)
                  ) : (
                    <tr>
                      <td colSpan={9} style={{ padding: "30px 25px" }}>
                        No guests matching your current search or filters.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Flex alignment="between top" className="guest-form-top-filters">
              <Pagination
                count={reservationContext.totalCnt}
                index={Number(criteria.page)}
                onIndexChanged={handlePageChange}
              />
              <RecordsPerPage />
            </Flex>
          </div>
        </div>
      </section>
      <GuestAdd />
      <GuestMessages />
      <GuestRate />
      <GuestShare />
      <GuestDetail />
      <WorkOrderAdd grId={grId} />
      <GuestDelete targetReservation={objGuest} criteria={criteria} />
      <NoteAdd
        itemId={
          reservationContext.reservation.guest_id ||
          reservationContext.reservation.guest_reservation_id
        }
        itemType="guest"
      />
    </>
  );
};

export default Guests;
